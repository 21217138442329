
const defaultState = {
    size: "",
    title: "",
    reference: "",
    oldReferences: [],
    description: "",
    sent: false,
    date: new Date(),
};


export default function(state = defaultState, action){

    if(action.type === "SIZE"){
        return { ...state, size: action.size };
    }

    if(action.type === "TITLE"){
        return { ...state, title: action.title };
    }

    if(action.type === "REFERENCE"){
        return { ...state, reference: action.reference };
    }

    if(action.type === "OLD_REFERENCES"){
        return { ...state, oldReferences: action.oldReferences };
    }

    if(action.type === "DESCRIPTION"){
        return { ...state, description: action.description };
    }

    if(action.type === "MESSAGE_SENT"){
        return { ...state, sent: action.sent };
    }

    if(action.type === "SET_DATE"){
        return { ...state, date: action.date };
    }

    return state;

};