import React from 'react';
import {RenderApp, store, dbRef, cloudFunctionsUrl} from '../../../index';
import App from './addDomainComponent';


/*Retrieve a list of all organizations*/
export async function getDomains(){

    const domains = dbRef.child('Domains').orderByKey();

    const itemArray = await domains.once("value").then(function(snapshot) {

        const array : any = [];

        snapshot.forEach(function(childSnapshot) {

            const name = childSnapshot.val().domain;
            const key = childSnapshot.key;
            let warnings:any = [];
            const disabled = childSnapshot.val().disabled;

            const organizations: any = childSnapshot.val().organisations;

            if(!organizations){
                warnings.push("Inga organisationer");
            }else{

                //Loop through the organisations and see if any data is missing
                for(let obj in organizations){

                    const contact = organizations[obj].contact;
                    const packages = organizations[obj].packages;
                    const background = organizations[obj].background;

                    const orgWarnings:string[] = [];

                    if(!contact){
                        warnings.push("Kontakt saknas");

                    }

                    if(!packages){
                        warnings.push("Paket saknas");
                    }

                    if(!background){
                        warnings.push("Bakgrund saknas");
                    }

                    const set:any = new Set([...warnings, ...orgWarnings]);//Remove all duplicates
                    warnings = [...set];//Insert the array without duplicates
    
                }
                
            }

            const domainData = {name: name, key: key, warnings: warnings, disabled:disabled};

            array.push(domainData);

        });
        return array;
    });
    store.dispatch({type: "UPDATE_DOMAINS", domains: itemArray});
}


function handleDomainChange(event : React.FormEvent<HTMLInputElement>){
    event.preventDefault();
    const domain = event.currentTarget.value;

    const domainForm = document.getElementById("domainForm");

    if(domainForm){//Remove error on email change
        domainForm.classList.remove("error");
        domainForm.classList.remove("message");
    } 

    store.dispatch({type: "ADD_DOMAIN", newDomain: domain});
}


function addDomain(event : React.FormEvent<HTMLInputElement>){
    event.preventDefault();

    let domain = store.getState().admin.newDomain.toLowerCase().trim();

    

    if(!domain){//Empty field
    
        domainError("Fältet måste vara ifyllt"); 
    
    }else if(!validateDomain(domain)){//Invalid domain

        domainError("Ogiltig domän");

    }else if(domainExists(domain)){//Already exists

        domainError("Domän existerar redan");

    }else{//Valid domain

        domain = domain.replace("@","");//Remove @ if any

        const container = document.getElementById("addDomain");

        if(container){//Add a loading animation
            container.classList.add("loading");
        }

        fetch(cloudFunctionsUrl+'/domain', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                domain: domain,
                idToken: store.getState().login.idToken,
            })
        
        }).then(function(response){ 

            return response.json();   
            
        }).then(async function(data){ 

            if(data.exists){
                if(container){//Add a loading animation
                    container.classList.remove("loading");
                }

                domainError("Domän existerar i en annan organisation");
            }else{
                await getDomains();    //retrieve the updated list

                if(container){//Add a loading animation
                    container.classList.remove("loading");
                }
            
                formMessage("Domän lades till","domainForm");

                RenderApp();
            }


        }).catch(function(){
            if(container){//Add a loading animation
                container.classList.remove("loading");
            }
        
            formMessage("Något gick fel","domainForm");
        });

    }
}


function domainError(message: string){
    const domainForm:any= document.getElementById("domainForm");

    const errorMessage = domainForm.querySelector(".errorMessage p span");

    errorMessage.innerHTML = message;

    if(domainForm){//Remove error on email change
        domainForm.classList.add("error");
    } 
}

function domainExists(domain: string): boolean{
    const domains = store.getState().admin.domains;

    for(let i = 0; i < domains.length; i++){
        if(domains[i].name === domain){
            return true;
        }
    }

    return false;
}

function validateDomain(domain: string): boolean{
    const re = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;
    return re.test(String(domain).toLowerCase())
}



function formMessage(message: string,formId: string){
    const form:any = document.getElementById(formId);

    const messageContainer = form.querySelector(".infoMessage p span");

    messageContainer.innerHTML = message;

    if(form){
        form.classList.add("message");
    } 
}



export default function(){
    return (
            <App state={store.getState()} 
                addDomain={addDomain} 
                domainChange={handleDomainChange}
                >
            </App>
        );
}

