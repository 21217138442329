import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle} from '@fortawesome/free-solid-svg-icons';




export default function (props :any){

    return(
        <div id="addOrg" className="card-container">
            <div className="content-wrapper">
                <div className="content">
                    <h1>{props.state.admin.viewDomain.domain}</h1>
                    <form id="orgForm" className="form" onSubmit={props.addOrganization}>
                        <div className="fieldContainer">
                            <span className='input-wrapper'>
                            <input 
                                name='domain' 
                                placeholder='&nbsp;' 
                                type='text' 
                                onChange={(e) => props.handleOrgChange(e.currentTarget.value)}
                            />
                            <label>Organisation (ex. Tross)</label>
                            </span>
                            <div className="errorMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Fältet får inte vara tomt.</span></p></div>
                            <div className="infoMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Meddelande</span></p></div>
                        </div>
                        <button className="button button-ripple" type="submit">
                            Lägg till organisation
                            <span className="pulse"></span>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}