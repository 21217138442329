const defaultState = {
    orgName: "",
    orgInfo: {},
    color: "",
    contact: "",
    packages: [],
    newPackage: "",
    backgroundUrl: "",
    preview:false,
};

export default function(state = defaultState, action){

    switch(action.type){
        case 'ORG_NAME': {
            return { ...state, orgName: action.orgName };
        }
        case 'ORG_COLOR': {
            return { ...state, color: action.color }
        }
        case 'ORG_BACKGROUND': {
            return { ...state, backgroundUrl: action.backgroundUrl}
        }
        case 'ORG_CONTACT': {
            return { ...state, contact: action.contact }
        }
        case 'ORG_PACKAGE': {
            return { ...state, newPackage: action.newPackage }
        }
        case 'ORG_PACKAGES': {
            return { ...state, packages: action.packages }
        }
        case 'ORG_INFO': {
            return { ...state, orgInfo: action.orgInfo};
        }
        case 'PREVIEW': {
            return { ...state, preview: action.preview};
        }
        default:
            return state;
    }
};

