import React from 'react';
import ChangePassword from '../pwChangeComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import AdRequestComponent from './AdRequestComponent';
import AddDomainComponent from './addDomainComponent';
import DomainList from './domainListComponent';
import DomainComponent from '../domainComponent';
import OrgComponent from '../orgComponent';
import UserComponent from '../userComponent';
import UsersComponent from '../usersComponent';

export default function (props :any){

    if(props.state.password.settings){
        
        return(
            <ChangePassword></ChangePassword> 
        );
    
    }else if(props.state.domain.viewUser){

            return(
                <UserComponent></UserComponent>
            );

    }else if(props.state.domain.viewOrganization){
        return(
            <OrgComponent></OrgComponent>
        );
    }else if(props.state.admin.viewDomain){

        return(
            <DomainComponent></DomainComponent>
        );
        

    }else{

        return(
            <main>
                <div className="main-container">
                    <div className="navigation">
                        <button className="button button-ripple no-bg invisible" onClick={props.goBack}>
                            Tillbaka
                            <span className="pulse"></span>
                        </button>
                    </div>
                    <AdRequestComponent/>
                    <AddDomainComponent></AddDomainComponent>
                    <DomainList></DomainList>

                    <div className="container-no-bg">
                        <h3>Inställningar</h3>
                        <ReceiverAddress
                            state={props.state}
                            changeReceiver={props.changeReceiver}
                            handleReceiverChange={props.handleReceiverChange}
                        >
                        </ReceiverAddress>
                        <AddAdmin
                            state={props.state}
                            addAdmin={props.addAdmin}
                            handleEmailChange={props.handleEmailChange}
                            handlePasswordChange={props.handlePasswordChange}
                        ></AddAdmin>
                    </div>
                    <UsersComponent></UsersComponent>
                </div>
            </main>
        );
    }
}




function ReceiverAddress(props:any){
    return(
        <div className="setting-wrapper">
            <p>Ändra mottagaraddress för jobbförfrågningar.</p>
            <form id="receiverForm" className="form"  onSubmit={(e) =>{e.preventDefault(); props.changeReceiver()}}>
                    <div className="fieldContainer">
                        <span className='input-wrapper'>
                        <input 
                            onChange={(e) => props.handleReceiverChange(e.currentTarget.value)} 
                            placeholder='&nbsp;' 
                            type='text'
                            defaultValue={props.state.admin.receiverMail}
                        />
                        <label>Email</label>
                        </span>
                        <div className="errorMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Fältet får inte vara tomt</span></p></div>
                        <div className="infoMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Meddelande</span></p></div>
                    </div>
            </form>
        </div>
    );
}

function AddAdmin(props:any){
    return(
        <div className="setting-wrapper">
            <p>Lägg till administratör.</p>
            <form id="adminForm" className="form"  onSubmit={(e) =>{e.preventDefault(); props.addAdmin()}}>
                    <div id="adminEmail" className="fieldContainer">
                        <span className='input-wrapper'>
                        <input 
                            onChange={(e) => {props.handleEmailChange(e.currentTarget.value)}} 
                            placeholder='&nbsp;' 
                            type='text'
                        />
                        <label>Email</label>
                        </span>
                        <div className="infoMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Meddelande</span></p></div>
                        <div className="errorMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Ogiltig mailaddress</span></p></div>
                    </div>
                    <button className="button button-ripple" type="submit">
                        Lägg till administratör
                        <span className="pulse"></span>
                    </button>
            </form>
        </div>
    );
}

