import React from 'react';
import App from './jobComponent';
import {store, dbStorage, RenderApp, dbRef, cloudFunctionsUrl} from '../../index';

import {getNewPost} from '../postsComponent';
import {validDate} from './datePicker';
import {settingError} from '../../common/errorMessage';

import {transitionState} from '../../common/transitionState';

import {getDomainFromEmail, getSenderFromEmail} from '../../common/emailFunctions';
import { initRipples } from '../../common/buttonRipple';


const functions = {
    typeChange: handleJobTypeChange,
    descriptionChange: handleDescriptionChange,
    send: sendJob,
    newMessage: newMessage,
    expandSelect,
    referenceChange: referenceChange,
    selectReference: selectReference,
    titleChange: titleChange,
}


function expandSelect(event){
    event.preventDefault();
    const parent = event.currentTarget.parentNode;

    if(parent){
        if(event.target.type === "text"){
            parent.classList.add("expanded");
        }else{
            parent.classList.toggle("expanded");
        }
    }

    document.addEventListener('click', function(event) {
      const isClickInside = parent.contains(event.target);
    
      if (!isClickInside) {
        parent.classList.remove("expanded");
      }
    });

}


function handleJobTypeChange(value,parentNode){
    const size = value;
    const parent = parentNode.parentNode;
    
    if(parent){
        parent.parentNode.classList.toggle("expanded");
    }

    const container = document.getElementById("sizeContainer");

    if(container){
        container.classList.remove("error");
    }

    store.dispatch({type: "SIZE", size: size});
    RenderApp(false);
}


function selectReference(value,parentNode){

    const parent = parentNode.parentNode;
    
    if(parent){
        parent.parentNode.classList.toggle("expanded");
    }

    const container = document.getElementById("referenceContainer");

    if(container){
        container.classList.remove("error");
    }


    store.dispatch({type: "REFERENCE", reference: value});
    RenderApp(false);
}

function referenceChange(newReference){
    store.dispatch({type: "REFERENCE", reference: newReference});

    const container = document.getElementById("referenceContainer");
    


    if(container){
        container.classList.remove("error");
        const expandedElt = container.querySelector(".expanded");
        if(expandedElt){
            expandedElt.classList.remove("expanded");
        }

    }

    RenderApp(false);
}

function titleChange(newTitle){
    store.dispatch({type: "TITLE", title: newTitle});

    const container = document.getElementById("titleContainer");

    if(container){
        container.classList.remove("error");
    }

    RenderApp(false);
}


function handleDescriptionChange(newDescription){
    const description = newDescription;
    store.dispatch({type: "DESCRIPTION", description: description});

    const container = document.getElementById("descriptionContainer");

    if(container){
        container.classList.remove("error");
    }

    RenderApp(false);
}

function newMessage(){
    store.dispatch({type: "SIZE", size: ""});
    store.dispatch({type: "TITLE", title: ""});
    store.dispatch({type: "DESCRIPTION", description: ""});
    store.dispatch({type: "REFERENCE", reference: ""});
    store.dispatch({type: "MESSAGE_SENT", sent: false});
    transitionState();
}

function sendJob(event : React.FormEvent){
    event.preventDefault();

    let error = false;

    if(store.getState().job.title.trim() == ""){
        settingError("Fältet får inte vara tomt.","titleContainer");
        error = true;
    }

    if(store.getState().job.reference.trim() == ""){
        settingError("Fältet får inte vara tomt.","referenceContainer");
        error = true;
    }

    if(store.getState().job.description.trim() == ""){
        settingError("Fältet får inte vara tomt.","descriptionContainer");
        error = true;
    }

    if(store.getState().job.size == ""){
        settingError("Fältet får inte vara tomt.","sizeContainer");
        error = true;
    }

    if(!validDate(store.getState().job.date)){
        error = true;
    }

    if(error){
        return;
    }


    sendEmail();

}


export async function getOldReferences(){
    const email = store.getState().login.email;
    const domainKey = getDomainFromEmail(email); //Gets the last 
    const sanitizedEmail = getSenderFromEmail(email);   //Replaces . with , 


    const getReferences = dbRef.child('Users').child(domainKey).child(sanitizedEmail).child('references').orderByChild('timestamp').limitToLast(10);

    const references:string[] = [];

    await getReferences.once('value',function(snapshot:any){

        snapshot.forEach(function(childSnapshot:any){
            references.push(childSnapshot.val().value)
        })

    });

    references.reverse();//Order by newest

    store.dispatch({type: "OLD_REFERENCES", oldReferences: references});

}



export async function getOrgInfo(email,orgKey:string){

        const domain = email.split("@")[1];

        /*Get the Organization name*/
        await fetch(cloudFunctionsUrl+`/organization?domain=${domain}&org=${orgKey}`).then(function(response:any){
            return response.json();
        }).then(async function(data){
            
            //Get the background url
            dbStorage.ref(data.domainKey + "/" + data.key + '/background.jpg').getDownloadURL().then(function(url) {
                /*Pre-load image*/
                const backgroundImage = new Image;
                backgroundImage.src = url;    
                //console.log("loading image")
                backgroundImage.onload = function() {

                    store.dispatch({type: "ORG_BACKGROUND", backgroundUrl: backgroundImage.src});
                    
                    setTimeout(function(){
                        RenderApp();
                    },500);

                }
            }).catch(function(error) {
                // Handle any errors
            });

            //store.dispatch({type: "ORG_BACKGROUND", backgroundUrl: imgUrl})
            store.dispatch({type: "ORG_COLOR", color: data.color});
            store.dispatch({type: "ORG_NAME", orgName: data.org});
            store.dispatch({type: "ORG_PACKAGES", packages: data.packages});

        });
        
}

function sendEmail(){
    const container = document.querySelector(".card-container .content-wrapper");

    if(container){//Add a loading animation
        container.classList.add("loading");
    }

    const email = store.getState().login.email;
    const org = store.getState().login.organization;
    const size = store.getState().job.size
    const title = store.getState().job.title;
    const reference = store.getState().job.reference;
    const description = store.getState().job.description;
    const startDate = store.getState().job.date;

    const domain = email.split("@")[1];

    /*fetch(cloudFunctionsUrl+'/organization', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            domain: domain,
            org: org,
        })
    })*/

    /*Get the Organization name*/
    fetch(cloudFunctionsUrl+`/organization?domain=${domain}&org=${org}`).then(function(response){
        return response.json();   
    }).then(async function(data){ 

        const org = data.org

        const newPost = {
            email: email,
            size: size,
            title: title,
            reference: reference,
            description: description,
            org: org,
            startDate: startDate,
        }

        await fetch(cloudFunctionsUrl+'/sendEmail', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newPost)
        }).then(async function(){

            store.dispatch({type: "MESSAGE_SENT", sent: true});

            //await getPreviousPosts(email);
            await getNewPost();

            if(container){//Add a loading animation
                container.classList.remove("loading");            
            }
            
            transitionState();

            updateReferences(reference);

        });

    }).catch(function(error){
        if(container){//Add a loading animation
            container.classList.remove("loading");            
        }

        settingError("Något gick fel.","descriptionContainer");

    });
}


function updateReferences(reference){
    const references = store.getState().job.oldReferences;

    for(let i = 0; i < references.length; i++){
        if(reference === references[i]){
            references.splice(i, 1);    //If the value already exist remove it
            break;//exit loop
        }
    }

    //The reference is moved to the top of the array
    references.unshift(reference);
    store.dispatch({type: "OLD_REFERENCES", oldReferences: references});
    
}


function closePreview(){
    store.dispatch({type:"PREVIEW",preview:false});
    RenderApp(false);
    setTimeout(function(){
        initRipples();
    },600)
    
}


export default function(){
    return <App state={store.getState()} handleJob={functions} closePreview={closePreview}></App>;
}