export function initRipples() {
	const buttons = document.querySelectorAll('.button-ripple');

	//Loop through all the buttons
	for(let i = 0; i< buttons.length; i++){
		
		//Set initial pulse size
		setPulseSize(buttons[i]);

		//bind our click event
		buttons[i].addEventListener('click', onButtonClick, false);
	}		
}


function setPulseSize(button) {

	const buttonWidth = 150;

	const pulseSize = buttonWidth * 2 + 'px';		
	const pulse = button.querySelector('.pulse');

	//set the pulse size to double the button's width
	pulse.style.width = pulseSize;
	pulse.style.height = pulseSize;
	pulse.style.marginLeft = - buttonWidth + 'px';
	pulse.style.marginTop = - buttonWidth + 'px';		
}


function onButtonClick(event) {
	//Check if the right button is clicked
	if(event.target.classList.contains('button-ripple')){

		const button = event.target;
		const r = button.getBoundingClientRect();

		
		const xPos = event.clientX - r.left;
		const yPos = event.clientY - r.top;
		
		const pulse = button.querySelector('.pulse');

		//the pulse will originate from where you click
		
		pulse.style.transform = "translate("+ xPos + "px," + yPos + "px) scale(0,0)";
		pulse.style.opacity = "1";

		increaseSize(pulse,xPos,yPos,0);

	}
}



function increaseSize(pulse,xPos,yPos,scale){
    

	pulse.style.transform = "translate("+ xPos + "px," + yPos + "px) scale("+scale+","+scale+")";
	pulse.style.opacity = 1 - scale;
	if(scale < 1){
		scale += 0.05;
		
		window.requestAnimationFrame(function(){
			increaseSize(pulse,xPos,yPos,scale);
		});	   
	}
}