import React from 'react'
import App from './loginComponent';
import {store} from '../../index';
import {dbAuth, dbRef, cloudFunctionsUrl} from '../../index';
import {transitionState} from '../../common/transitionState';
import {getPreviousPosts, addPostHistoryListener} from '../postsComponent';
import {getOrgInfo, getOldReferences} from '../jobComponent';

import { getAdminInfo} from '../adminComponent';
import { getDomains } from '../adminComponent/addDomainComponent';

import {settingError} from '../../common/errorMessage';

import {validateEmail, getDomainFromEmail, getSenderFromEmail} from '../../common/emailFunctions';
import { getOrganizationsByDomain } from '../domainComponent';

const localStorage = window.localStorage;

export function login(email:string,password:string){
    
    store.dispatch({type: "EMAIL", email: email});

    let error = false;

    //Basic field check
    if(!email){
        loginError("Fältet måste vara ifyllt","emailField"); 
        error = true;
    }else if(!validateEmail(email)){
        loginError("Ogiltig mailaddress","emailField"); 
        error = true;
    }
    
    if(!password){
        loginError("Fältet måste vara ifyllt","passwordField"); 
        error = true;
    }

    if(error){
        return;
    }

        
    const container = document.querySelector(".card-container .content-wrapper");

    //Add a loading animation
    if(container){
        container.classList.add("loading");
    }

    //Try and login with email
    dbAuth.signInWithEmailAndPassword(email,password).then(async function() {
        //Successfully logged in

        const domainKey = getDomainFromEmail(email); //Gets the last 
        const sanitizedEmail = getSenderFromEmail(email);   //Replaces . with , 

        let lastLogin;//Save last login time to db

        if(dbAuth.currentUser){
            lastLogin = dbAuth.currentUser.metadata.lastSignInTime;
        }
        
        /*Check if the organisation is enabled or not*/
        fetch(cloudFunctionsUrl+'/domain/valid', {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email
            })
        
        }).then(function(response){ 
            return response.json();   
        }).then(function(data){ 

            if(data.validDomain){

                /* Update the last login date in database */
                dbRef.child('Users').child(domainKey).child(sanitizedEmail).update({
                    lastLogin: lastLogin,
                    emailVerified: true,
                }).then(function(){

                    /*Check if admin*/
                    const checkIfAdmin = dbRef.child("Admins").orderByChild("email").equalTo(email).limitToFirst(1);

                    checkIfAdmin.once("value", snapshot => {

                        if(snapshot.exists()){
                            store.dispatch({type: "ADMIN", isAdmin: true});
                        }else{
                            store.dispatch({type: "ADMIN", isAdmin: false});
                        }

                    }).then(async function(){

                        // Sign-in successful
                        localStorage.setItem('email', store.getState().login.email);//Set local storage
                        localStorage.setItem('password', store.getState().login.password);//Set local storage

                        store.dispatch({type: "PASSWORD", password: ""});
                        
                        //Retrieve the idToken for future backend validation
                        if(dbAuth.currentUser){
                            dbAuth.currentUser.getIdToken(true).then(function(idToken) {
                                store.dispatch({type: "ID_TOKEN", idToken: idToken});
                                }).catch(function(error) {
                                // Handle error
                                console.log(error)
                            });
                        }
                    

                        //Transition state if admin
                        if(store.getState().login.isAdmin){
                            store.dispatch({type: "LOGGED_IN", isLoggedIn: true});

                            await getAdminInfo();
                            await getDomains();

                            if(container){
                                container.classList.remove("loading");
                            }

                            transitionState();  

                        }else{//Regular login
                            store.dispatch({type: "LOGGED_IN", isLoggedIn: true});

                            const checkOrganization = dbRef.child('Users').child(domainKey).child(sanitizedEmail);

                            //Check if user has an organisation registered
                            await checkOrganization.once('value',async function(snapshot){

                                let org = snapshot.val().organisation;
                                let confirmed = snapshot.val().confirmed;

                                /*Check if organization exists*/
                                await fetch(cloudFunctionsUrl+`/organization?domain=${email.split("@")[1]}&org=${org}`).then(function(response:any){
                                    return response.json();
                                }).then((json:any)=>{
                                    
                                    console.log(json)

                                    if(json.status === 404){//Organization is not found (Probably deleted)
                                        
                                        //Remove previous values
                                        org = null;
                                        confirmed = null;

                                        checkOrganization.update({
                                            "organisation": org,
                                            "confirmed": confirmed,
                                        });

                                    }

                                }).then(async function(){

                                    //check if user is confirmed and has an organisation
                                    if(confirmed === true && org){
                                        store.dispatch({type: "SET_ORG", organization: org});
                                        await getOrgInfo(email,org);//In that case get org info
                                        await getPreviousPosts(store.getState().login.email); //Load in previous posts from user
                                        await getOldReferences();


                                        transitionState();

                                        //Add post listeners after the transition is done after 600milliseconds.
                                        const posts = store.getState().posts.previousPosts;

                                        //Add eventlistener to each child to watch for updates
                                        for(let i = 0; i < posts.length; i++){
                                            addPostHistoryListener(posts[i].key);
                                        }

                                    }else if(org){//If the user has an org but isn't confirmed

                                        if(container){
                                            container.classList.remove("loading");
                                        }

                                        loginMessage("Förfrågan inväntar validering");

                                    }else{

                                        /*
                                            If the user neither has an organisation and is confirmed
                                            Load in the organisations for that specific domain and 
                                            transition to choose organisation screen.                                      
                                        */
                                        const domain = email.split("@")[1];
                                        await getOrganizationsByDomain(domain);

                                        transitionState();  
                                    }


                                });


                            });
                        }


                    });
                }).catch(function(){
                    loginError("Något gick fel","emailField");
                });


            }else{
                if(container){
                    container.classList.remove("loading");
                }

                loginError("Organisation ej aktiverad","emailField");
            }
        
        }).catch(function(){
            // Account disabled
            if(container){
                container.classList.remove("loading");
            }
            loginError("Något gick fel","emailField");
        });

    }).catch(function(error){

        if(container){
            container.classList.remove("loading");
        }

        if(error.code === "auth/user-disabled"){
            // Account disabled
            loginError("Kontot spärrat","passwordField");
        }else{
            loginError("Inloggning misslyckades","passwordField");
        }
    });



}

export function loginError(message: string,field:string){
    settingError(message,field);
}

export function loginMessage(message: string){

    const loginForm:any = document.getElementById("loginForm");

    const loginMessage = loginForm.querySelector(".loginMessage p span");

    loginMessage.innerHTML = message;

    if(loginForm){
        loginForm.classList.add("message");
    } 
}

function goToForgotPw(){
    store.dispatch({type: "FORGOT_PASSWORD", forgotPassword: true});
    store.dispatch({type: "EMAIL", email: ""});
    transitionState();
}

function goToCreateAccount(){
    store.dispatch({type: "CREATE_ACCOUNT", createAccount: true});
    store.dispatch({type: "EMAIL", email: ""});
    transitionState();
}

export default function(){
    return( <App state={store.getState()} login={login} goToForgotPw={goToForgotPw} goToCreateAccount={goToCreateAccount}></App>);
}