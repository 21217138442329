import React from 'react';
import './App.css';
import Header from './components/header';
import Login from './components/loginComponent';
import JobComponent from './components/jobComponent';
import PostsComponent from './components/postsComponent';
import AdminHeader from './components/headerAdmin';
import AdminComponent from './components/adminComponent';
import ChooseOrg from './components/chooseOrgComponent';
import Settings from './components/pwChangeComponent';

function App(props :any) {
  if(props.state.login.isLoggedIn && props.state.login.isAdmin){
    return(
      <div className="App">
        <div className="loading-container"></div>
        <AdminHeader></AdminHeader>
        <AdminComponent></AdminComponent>
      </div>
    );
  }else if(props.state.login.isLoggedIn){

    if(props.state.login.organization === ""){
      return(
      <div className="App" >
          <div className={props.state.organization.backgroundUrl ? "background" : "background hide"} style={{backgroundImage: "url('" + props.state.organization.backgroundUrl + "')", backgroundSize: 'cover', backgroundPosition: 'center'}}></div>
          <Header></Header>
          <ChooseOrg></ChooseOrg>
      </div>
      );
    }

    if(props.state.password.settings){
      return(
        <div className="App" >
            <div className={props.state.organization.backgroundUrl ? "background" : "background hide"} style={{backgroundImage: "url('" + props.state.organization.backgroundUrl + "')", backgroundSize: 'cover', backgroundPosition: 'center'}}></div>
            <Header></Header>
            <Settings></Settings>
        </div>
      );
    }

    return(
      <div className="App" >
          <div className={props.state.organization.backgroundUrl ? "background" : "background hide"} style={{backgroundImage: "url('" + props.state.organization.backgroundUrl + "')", backgroundSize: 'cover', backgroundPosition: 'center'}}></div>
          <Header></Header>
          <JobComponent></JobComponent>
          <PostsComponent></PostsComponent>
      </div>
    );

    
  }

  return (
    <div className="App">
      <div className={props.state.organization.backgroundUrl ? "background" : "background hide"} style={{backgroundImage: "url('" + props.state.organization.backgroundUrl + "')", backgroundSize: 'cover', backgroundPosition: 'center'}}></div>
      <Login></Login>
    </div>
  );
}

export default App;
