import React from 'react';
import shortid from "shortid";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faExclamationTriangle, faTrash, faLockOpen, faBan, faExclamationCircle, faInfoCircle} from '@fortawesome/free-solid-svg-icons';

import AddOrganization from './addOrgComponent';

import UsersComponent from '../usersComponent';

export default function (props :any){

    const returnVal:any = [];

    const organizations = props.state.domain.organizations;


    if(organizations.length === 0){
        returnVal.push(

            <li className="list-item" key={shortid.generate()}>
                <button className="button button-ripple no-bg disabled">
                    <span className="innerText">Inga organisationer</span>
                    <span className="pulse"></span>
                </button>
                <div className="info">
                    <FontAwesomeIcon icon={faInfoCircle}/>
                </div>
            </li>    

        );
    }

    for(let i = 0; i < organizations.length; i++){

        returnVal.push(
            <li className="list-item" key={organizations[i].key}>
                <button className="button button-ripple no-bg" data-org={organizations[i].key} data-name={organizations[i].name} onClick={props.viewDetails}>
                    <FontAwesomeIcon icon={faPen}/>
                    <span className="innerText">{organizations[i].name}</span>
                    <span className="pulse"></span>
                </button>
                <OrgInfo orgInfo={organizations[i]}></OrgInfo>
            </li>           
        );
    }


        return(
            <main>
                <div className="main-container">
                    <div className="navigation">
                        <button className="button button-ripple no-bg" onClick={props.goBack}>
                            Tillbaka
                            <span className="pulse"></span>
                        </button>
                    </div>
                    <AddOrganization></AddOrganization>
                    <ul className="list-container">
                        <div className="titleWrapper">
                            <h3>Organisationer</h3>
                        </div>
                        {returnVal}
                    </ul>  
                </div>
                <UsersComponent></UsersComponent>
                <DisableDomain
                        state={props.state}
                        disableDomain={props.disableDomain}>
                </DisableDomain>
    
                <DeleteDomain 
                        state={props.state} 
                        confirmDelete={props.confirmDelete}
                        deleteDomain={props.deleteDomain}
                ></DeleteDomain>
            </main>
        );
    

}

function OrgInfo(props:any){

    const returnVal:any = [];

    if(props.orgInfo.contact === undefined){
        returnVal.push(
            <li key={shortid.generate()}>Kontakt saknas</li>
        );
    }

    if(props.orgInfo.background === undefined || props.orgInfo.background === false){
        returnVal.push(
            <li key={shortid.generate()}>Bakgrund saknas</li>
        );
    }

    if(props.orgInfo.packages === undefined){
        returnVal.push(
            <li key={shortid.generate()}>Paket saknas</li>
        );
    }

    if(returnVal.length == 0){
        return(

            <div className="info">
            </div>
    
        );
    }

    return(

        <div className="info warning">
            <FontAwesomeIcon icon={faExclamationCircle}/>
            <ul>
                {returnVal}
            </ul>
        </div>

    );

}


function DeleteDomain(props:any){
    return(
        <div className="delete-container">
            <div className="container-no-bg">
                <h4>På egen risk</h4>
                <p>Raderar domänen @{props.state.admin.viewDomain.domain} och alla dess tillhörande organisationer.</p>
                <button className="button button-ripple warning" onClick={props.confirmDelete}>
                    <FontAwesomeIcon icon={faExclamationTriangle}/>
                    <span className="innerText">Radera domän</span>
                    <span className="pulse"></span>
                </button>
            </div>
            <div className="confirm-delete" id="delete-confirm">
                <div className="container">
                    <div className="content-wrapper">
                        <div className="content">
                            <h2>Radera @{props.state.admin.viewDomain.domain}?</h2>
                            <div className="confirmButtons">
                                <button className="button button-ripple warning" onClick={props.deleteDomain}>
                                    <FontAwesomeIcon icon={faTrash}/>
                                    <span className="innerText">Ja</span>
                                    <span className="pulse"></span>
                                </button>
                                <button className="button button-ripple no-bg" onClick={props.confirmDelete}>
                                    <span className="innerText">Nej</span>
                                    <span className="pulse"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function DisableDomain(props:any){

    if(props.state.admin.viewDomain.disabled){
        return(

            <div className="container-no-bg">
                <button id="disableDomain" className="button button-ripple" onClick={props.disableDomain}>
                    <FontAwesomeIcon icon={faLockOpen}/>
                    <span className="innerText">Aktivera domän</span>
                    <span className="pulse"></span>
                </button>
            </div>
    
        );
    }else{
        return(

            <div className="container-no-bg">
                <button id="disableOrg" className="button button-ripple warning" onClick={props.disableDomain}>
                    <FontAwesomeIcon icon={faBan}/>
                    <span className="innerText">Inaktivera domän</span>
                    <span className="pulse"></span>
                </button>
            </div>
    
        );
    }

}