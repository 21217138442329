import React from 'react'
import App from './postsComponent';

import {store, RenderApp, cloudFunctionsUrl} from '../../index';
import {dbRef} from '../../index';


import {getSenderFromEmail, getDomainFromEmail} from '../../common/emailFunctions';

import {formatDate} from '../userComponent';

const months = ["Januari","Februari","Mars","April","Maj","Juni","Juli","Augusti","September","Oktober","November","December"];



async function loadHistory(){
    const email = store.getState().login.email;//The logged in users email

    const viewEmail = store.getState().domain.viewUser.trim();//The user that is viewed on the admin page


    const container = document.querySelector(".posts-container ul");


    if(container){
        container.classList.add("loading");
    }

    if(viewEmail){//If viewing a specific user on the admin page
        await getPreviousPosts(viewEmail);
    }else{//Viewing users own email while logged in (not admin page)
        await getPreviousPosts(email);
    }

    if(container){
        container.classList.remove("loading");
    }

    RenderApp();
}

/*Get the latest post after sending a job request*/
export async function getNewPost(){
    const email = store.getState().login.email;

    const sender = getSenderFromEmail(email);
    const domain = getDomainFromEmail(email);

    const newPost = dbRef.child('Users').child(domain).child(sender).child('JobAdverts').orderByKey().limitToLast(1);
    
    let postObj; 


    await newPost.once("value").then(async function(snapshot) {

        await snapshot.forEach(function(childSnapshot) {
            
            const date = new Date(childSnapshot.val().date);
            const fullDate = formatDate(date);
            let dateString = date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
            const startDate = formatDate(new Date(childSnapshot.val().startDate),false);

            const statusMessage = getStatusMessage(childSnapshot.val().status);

            postObj = {
                key: childSnapshot.key,
                status: childSnapshot.val().status,
                statusMessage: statusMessage,
                date: dateString,
                fullDate: fullDate,
                size: childSnapshot.val().size,
                title: childSnapshot.val().title,
                reference: childSnapshot.val().reference,
                description: childSnapshot.val().description,
                ip: childSnapshot.val().ip,
                city: childSnapshot.val().city,
                country: childSnapshot.val().country,
                adId: childSnapshot.val().adId, 
                startDate: startDate,
            }

            const posts = store.getState().posts.previousPosts;
        
            posts.unshift(postObj);
        
            store.dispatch({type: "POSTS", previousPosts: posts});

            addPostHistoryListener(childSnapshot.key);
        });

    });

}

export async function getPreviousPosts(email){
    const sender = getSenderFromEmail(email);   //replace . with ,
    const domain = getDomainFromEmail(email);

    const loadingInterval = 10;//Change this to load in more posts each time

    let getPosts;//Query

    //Get last post in the list, to be able to retrieve the ones after
    let lastKnownPostValue = store.getState().posts.lastKnownPostValue;

    if(lastKnownPostValue){
        getPosts = dbRef.child('Users').child(domain).child(sender).child('JobAdverts').orderByKey().limitToLast(loadingInterval).endAt(lastKnownPostValue);
    }else{
        store.dispatch({type: "POSTS", previousPosts: []});
        getPosts = dbRef.child('Users').child(domain).child(sender).child('JobAdverts').orderByKey().limitToLast(loadingInterval);
    }

    const itemArray = await getPosts.once("value").then(async function(snapshot) {

        const array : any = [];

        await snapshot.forEach(function(childSnapshot) {

            if(childSnapshot.exists()){
                const date = new Date(childSnapshot.val().date);
                const fullDate = formatDate(date);
                let dateString = date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
                const startDate = formatDate(new Date(childSnapshot.val().startDate),false);

                const statusMessage = getStatusMessage(childSnapshot.val().status);

                const item = {
                    key: childSnapshot.key,
                    status: childSnapshot.val().status,
                    statusMessage: statusMessage,
                    date: dateString,
                    fullDate: fullDate,
                    size: childSnapshot.val().size,
                    title: childSnapshot.val().title,
                    reference: childSnapshot.val().reference,
                    description: childSnapshot.val().description,
                    ip: childSnapshot.val().ip,
                    city: childSnapshot.val().city,
                    country: childSnapshot.val().country,
                    adId: childSnapshot.val().adId, 
                    startDate: startDate,
                }
        
                lastKnownPostValue = childSnapshot.key;

                array.push(item);
            }

        });

        if(array.length > 0){
            lastKnownPostValue = array[0].key;
            store.dispatch({type:"POSTS_INDEX", lastKnownPostValue: lastKnownPostValue})
            return array.reverse();
        }else{
            return array;
        }

    });


    const previousPosts = store.getState().posts.previousPosts;

    //See if there are duplicates and remove them
    if(previousPosts.length > 0 && itemArray.length > 0){
        
        if(itemArray[0].key === previousPosts[previousPosts.length-1].key){
            itemArray.shift();
        }        

        const viewEmail = store.getState().domain.viewUser.trim();//The user that is viewed on the admin page
                                        
        if(!viewEmail){
            //Add eventlisteners to the previous posts
            for(let i = 0; i < itemArray.length; i++){
                addPostHistoryListener(itemArray[i].key)
            }
        }
    }



    //Merge new values with old
    const posts = [...previousPosts, ...itemArray];

    store.dispatch({type: "POSTS", previousPosts: posts});

}

export function addPostHistoryListener(key){
    const email = store.getState().login.email;

    const sender = getSenderFromEmail(email);
    const domain = getDomainFromEmail(email);

    const userPost = dbRef.child('Users').child(domain).child(sender).child('JobAdverts').child(key);

    userPost.on('value', async function(snapshot:any) {

        const posts = store.getState().posts.previousPosts;

        for(let i = 0; i < posts.length; i++){

            if(posts[i].key === snapshot.key){
                posts[i].status = snapshot.val().status;
                posts[i].statusMessage = getStatusMessage(snapshot.val().status);
                break;
            }

        }

        store.dispatch({type: "POSTS", previousPosts: posts});

        //A slight delay so that it doesn't render during and disrupts any transition animation
        setTimeout(function(){
            RenderApp();
        },600);

    });
}


/*A function for creating status string.*/
function getStatusMessage(status:string):string{
    let statusMessage = "";

    if(status === "sent"){
        statusMessage = "Skickad, inväntar bekräftelse.";
    }else if(status === "confirmed"){
        statusMessage = "Bekräftad";
    }else if(status === "active"){
        statusMessage = "Aktiv";
    }else if(status === "done"){
        statusMessage = "Klar";
    }

    return statusMessage;
}

function expandItem(event : any){
    event.preventDefault();

    //toggle expand
    const clickedItem = event.target.parentNode.parentNode;//Get the clicked parent

    //Retrieve previous opened list item
    const previousListItem:any = document.querySelector(".posts-container .post-item.expand");

    if(previousListItem){//Close the previous item
        previousListItem.classList.remove("expand");
        previousListItem.querySelector(".description").style.height = 0 + "px";

        if(previousListItem.querySelector(".listHeader") !== event.target){//Only open the newly clicked list item unless wasn't the previously opened
            expand(clickedItem);
        }

    }else{//If there isn't a previously opened value, open without closing
        expand(clickedItem);
    }

}

function expand(item){
    const description = item.querySelector(".description");
    const content:any = description.querySelector(".description-content");
    const height = content.offsetHeight;

    description.style.height = height + "px";

    item.classList.add("expand");
}


function changeStatus(status:string,postKey:string){

    const email = store.getState().domain.viewUser;

    const sender = getSenderFromEmail(email);
    const domain = getDomainFromEmail(email);

    const post = dbRef.child('Users').child(domain).child(sender).child('JobAdverts').child(postKey);

    const container = document.querySelector(".expand");

    if(container){
        container.classList.add("loading");
    }

    post.update({
        "status":status
    }).then(function(){
        
        if(container){
            container.classList.remove("loading");
        }

        const posts = store.getState().posts.previousPosts;

        for(let i = 0; i < posts.length; i++){

            if(posts[i].key === postKey){
                posts[i].status = status;
                posts[i].statusMessage = getStatusMessage(status);
                break;
            }

        }

        RenderApp();

    }).then(function(){
        
        fetch(cloudFunctionsUrl+'/jobRequest/sendStatusUpdate', {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                status: status,
                email: email,
                postKey: postKey
            })
        })

    }).catch(function(){
        if(container){
            container.classList.remove("loading");
        }
    });

}





export default function(){
    return <App state={store.getState()} expand={expandItem} loadHistory={loadHistory} changeStatus={changeStatus}></App>;
}