import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';


export default function(props :any){
    const valueArr:Array<string> = props.state.domain.organizations;

    return( 
        <div className="card-container">
            <div className="content-wrapper">
            <div className="content">
                <h1>Vilken organisation tillhör du?</h1>        
                <form className="form" onSubmit={(e) => {e.preventDefault(); props.submitOrg();}}>
                <div className="fieldContainer" id="orgContainer">
                    <span className='input-wrapper select'>
                    <SelectMenu expandSelect={props.expandSelect} handleOrgChange={props.handleOrgChange} values={valueArr}></SelectMenu>
                    <label>Välj organisation</label>
                    </span>
                    <div className="errorMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Fältet får inte vara tomt.</span></p></div>
                </div>

                <span className="button-wrapper">
                    <button className="button button-ripple" type='submit'>
                    Bekräfta
                    <span className="pulse"></span>
                    </button>

                </span>
                </form>
            </div>
            </div>
        </div>
    );

}



function SelectMenu(props :any){
    
  let returnVal:any = [];

  for(let i:number = 0; i < props.values.length; i++){

    //Add organisation only if it has contact and packages
    if(props.values[i].contact && props.values[i].packages){

      returnVal.push(
        <button className="select-item button-ripple" 
        tabIndex={0} 
        key={props.values[i].key} 
        onClick={(e) => {
          e.preventDefault(); 
  
          const elt = e.currentTarget.parentNode;
  
          props.handleOrgChange(props.values[i],elt);
        
        }}>
          {props.values[i].name}
          <span className="pulse"></span>
        </button>
      );

    }

  }

  return (
    <div className="select-container">
      <button className="selected-value button-ripple" tabIndex={0} onClick={props.expandSelect}>
        <span className="text">&nbsp;</span>
        <span className="pulse"></span>
      </button>
        <div className="item-container">
          {returnVal}
        </div>
    </div>
  );
}
