import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCog } from '@fortawesome/free-solid-svg-icons';

export default function (props :any){
    return(
      <header>
          <div className="header-wrapper">
            <button className="button button-ripple no-bg" onClick={props.settings}>
                <FontAwesomeIcon icon={faUserCog}/>
                <span className="innerText">{props.state.login.email}</span>
                <span className="pulse"></span>
            </button>

            <button className="button button-ripple" onClick={props.logout}>
              Logga ut
              <span className="pulse"></span>
            </button>
          </div>
      </header>
    );
  }