import React from 'react';
import ReactDOM, { render } from 'react-dom';
import './index.css';
import App from './App';
import {createStore, applyMiddleware, combineReducers} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {take} from 'redux-saga/effects';

import {login} from './components/loginComponent';
import {initRipples} from './common/buttonRipple';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';


import loginReducer from './components/loginComponent/loginReducer';
import jobReducer from './components/jobComponent/jobReducer';
import postsReducer from './components/postsComponent/postsReducer';
import adminLoginReducer from './components/adminLoginComponent/adminLoginReducer';
import orgReducer from './components/orgComponent/orgReducer';
import pwReducer from './components/pwChangeComponent/pwReducer';
import adminReducer from './components/adminComponent/adminReducer';
import userReducer from './components/userComponent/userReducer';
import domainReducer from './components/domainComponent/domainReducer';


// Initialize Firebase
/*const config = {
    apiKey: "AIzaSyC35K5cZUOD2MIoTMqcXglepaXE9hYRKSM",
    authDomain: "kai2-38189.firebaseapp.com",
    databaseURL: "https://kai2-38189.firebaseio.com",
    projectId: "kai2-38189",
    storageBucket: "kai2-38189.appspot.com",
    messagingSenderId: "1015663572058"
};*/

/*const config = {
    apiKey: "AIzaSyB9F-QqeS0owuNKbsIikFKKA9CQgCA42SY",
    authDomain: "tross-ad-platform.firebaseapp.com",
    databaseURL: "https://tross-ad-platform.firebaseio.com",
    projectId: "tross-ad-platform",
    storageBucket: "tross-ad-platform.appspot.com",
    messagingSenderId: "62600006743",
    appId: "1:62600006743:web:8e3bcc849e205811"
};*/

  var firebaseConfig = {
    apiKey: "AIzaSyB9F-QqeS0owuNKbsIikFKKA9CQgCA42SY",
    authDomain: "tross-ad-platform.firebaseapp.com",
    databaseURL: "https://tross-ad-platform.firebaseio.com",
    projectId: "tross-ad-platform",
    storageBucket: "tross-ad-platform.appspot.com",
    messagingSenderId: "62600006743",
    appId: "1:62600006743:web:8cb221ef91b4c1867c2712",
    measurementId: "G-JK0QSKCC4C"
  };


firebase.initializeApp(firebaseConfig);

//export const cloudFunctionsUrl = "https://us-central1-kai2-38189.cloudfunctions.net/widgets";
export const cloudFunctionsUrl = "https://us-central1-tross-ad-platform.cloudfunctions.net/app/kai";

export const dbAuth = firebase.auth();
export const dbRef = firebase.database().ref();
export const dbStorage = firebase.storage();

const rootReducer = combineReducers({
    login: loginReducer,
    job: jobReducer,
    posts: postsReducer,
    adminLogin: adminLoginReducer,
    organization: orgReducer,
    password: pwReducer,
    admin: adminReducer,
    user: userReducer,
    domain: domainReducer,
});

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));


sagaMiddleware.run(actionSaga);


//TODO
function* actionSaga(){
    while(true){
        const action = yield take();
    }

}

/*Login if email and password is stored in localStorage*/
if(localStorage.getItem('email') && localStorage.getItem('password')){
    const email = localStorage.getItem('email');
    const password = localStorage.getItem('password');
    store.dispatch({type: "EMAIL", email: email});
    store.dispatch({type: "PASSWORD", password: password});
}


export function RenderApp(ripples = true){
 
    ReactDOM.render(
        <App state={store.getState()}/>,
        document.getElementById('root')
    );

    if(ripples){
        initRipples();
    }
    
}

RenderApp();

if(localStorage.getItem('email') && localStorage.getItem('password')){
    const email:string = ""+localStorage.getItem('email');
    const password:string = ""+localStorage.getItem('password');
    login(email,password);
}