import React from 'react';
import {RenderApp, dbRef, store, dbStorage,cloudFunctionsUrl} from '../../index';
import App from './orgComponent';
import {transitionState} from '../../common/transitionState';
import {getOrganizationsById} from '../domainComponent';
import {validateEmail} from '../../common/emailFunctions';
import {getUsers} from '../usersComponent';
import {settingError} from '../../common/errorMessage';
import {orgExists} from '../domainComponent/addOrgComponent';
import { initRipples } from '../../common/buttonRipple';


export async function getOrgInfo(orgId){

    const domainId = store.getState().admin.viewDomain.key;

    const org = dbRef.child('Domains').child(domainId).child('organisations').child(orgId);

    const orgInfo = await org.once("value").then(async function(snapshot) {

        if(snapshot.exists()){
            const key = snapshot.key;
            const disabled = snapshot.val().disabled;
            const color = snapshot.val().color;
            const contact = snapshot.val().contact;
            const packages: any = snapshot.val().packages;

            const orgPackages : any = [];

            for(let obj in packages){

                const name = packages[obj].name;
                orgPackages.push(name);

            }

            const infoObj = {
                key: key, 
                disabled: disabled,
                color: color,
                contact: contact,
            };

            store.dispatch({type: "ORG_COLOR", color: infoObj.color});
            store.dispatch({type: "ORG_PACKAGES", packages: orgPackages});




            let imgUrl;
            //Get organisation background
            await dbStorage.ref(domainId + "/" + key + '/background.jpg').getDownloadURL().then(function(url) {
                imgUrl = url;
            }).catch(function(error) {
                // Handle any errors
            });

            store.dispatch({type: "ORG_BACKGROUND", backgroundUrl: imgUrl});

            if(!contact){
                store.dispatch({type: "ORG_CONTACT", contact: ""});
            }else{
                store.dispatch({type: "ORG_CONTACT", contact: infoObj.contact});
            }


            return infoObj;
        }else{
            return null;
        }
    });


    store.dispatch({type: "ORG_INFO", orgInfo: orgInfo});
}



export function formMessage(message: string,formId: string){
    const form:any = document.getElementById(formId);

    const messageContainer = form.querySelector(".infoMessage p span");

    messageContainer.innerHTML = message;

    if(form){
        form.classList.add("message");
    } 
}


async function goBack(){
    store.dispatch({type: "VIEW_ORGANIZATION", viewOrganization: ""});
    store.dispatch({type: "ORG_COLOR", color: ""});

    const container = document.querySelector(".loading-container");

    if(container){
        container.classList.add("loading")
    }

    await getUsers(store.getState().admin.viewDomain.domain).then(async function(){
        await getOrganizationsById(store.getState().admin.viewDomain.key).then(function(){
            if(container){
                container.classList.remove("loading")
            }
    
            transitionState();
        });

    });


}


function showColorInput(){
    const container = document.getElementById("colorContainer");

    if(container){
        container.classList.toggle('changingInput');
    
        const input = container.querySelector('input');

        if(input){
            input.value = store.getState().organization.color;
        }
    
    }
}

function handleColorChange(newColor:string){

    store.dispatch({type: "ORG_COLOR", color: newColor.trim().toLowerCase()});


    const form = document.getElementById('colorForm');

    if(form){
        form.classList.remove("error");
    }
}

function changeColor(){

    const color = store.getState().organization.color;

    if(!color){//Check if field is empty
        //error if empty
        settingError("Fältet får inte vara tomt","colorForm");
    }else if(!/(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(color)){ //Check if valid color
        //error if not valid color
        settingError("Ogiltig färg","colorForm");
    }else{

        const orgContainer = document.querySelector("#orgContainer .content-wrapper");

        if(orgContainer){
            orgContainer.classList.add("loading");
        }

        const domain = store.getState().admin.viewDomain.key;
        const organisation = store.getState().domain.viewOrganization;

        dbRef.child('Domains').child(domain).child('organisations').child(organisation).update({
            "color": color
        }).then(async function(){
            const org = store.getState().domain.viewOrganization;
            await getOrgInfo(org);

            if(orgContainer){
                orgContainer.classList.remove("loading");
            }

            showColorInput();
            RenderApp();
        });
    }
}


function showContactInput(){
    const nameContainer = document.getElementById("contactContainer");

    if(nameContainer){
        nameContainer.classList.toggle('changingInput');
    
        const input = nameContainer.querySelector('input');

        if(input){
            input.value = store.getState().organization.contact;
        }
    
    }
}


function handleContactChange(newContact:string){
    store.dispatch({type: "ORG_CONTACT", contact: newContact.trim()});

    const form = document.getElementById('contactForm');

    if(form){
        form.classList.remove("error");
    }
}

function changeContact(){

    const contactMail = store.getState().organization.contact.trim();

    
    if(contactMail === ""){ //Check if valid email
        const orgContainer = document.querySelector("#orgContainer .content-wrapper");

        const domain = store.getState().admin.viewDomain.key;
        const organisation = store.getState().domain.viewOrganization;

        dbRef.child('Domains').child(domain).child('organisations').child(organisation).update({
            "contact": null
        }).then(async function(){
            if(orgContainer){
                orgContainer.classList.add("loading");
            }

            const org = store.getState().domain.viewOrganization;
            await getOrgInfo(org);

            if(orgContainer){
                orgContainer.classList.remove("loading");
            }

            RenderApp();
        });
    }else if(!validateEmail(contactMail)){ //Check if valid email
        settingError("Ogiltig mailaddress","contactForm");
    }else{

        const orgContainer = document.querySelector("#orgContainer .content-wrapper");

        if(orgContainer){
            orgContainer.classList.add("loading");
        }

        const domain = store.getState().admin.viewDomain.key;
        const organisation = store.getState().domain.viewOrganization;

        dbRef.child('Domains').child(domain).child('organisations').child(organisation).update({
            "contact": contactMail
        }).then(async function(){
            const org = store.getState().domain.viewOrganization;
            await getOrgInfo(org);

            if(orgContainer){
                orgContainer.classList.remove("loading");
            }

            showContactInput();
            RenderApp();
        });
    }

}



function showNameInput(){
    const nameContainer = document.getElementById("orgName");

    if(nameContainer){
        nameContainer.classList.toggle('changingInput');
    
        const input = nameContainer.querySelector('input');

        if(input){
            input.value = store.getState().organization.orgName;
        }
    
    }
}

function changeName(){

    if(!store.getState().organization.orgName){
        settingError("Fältet får inte vara tomt","nameForm");
    }else if(orgExists(store.getState().organization.orgName)){
        settingError("Namnet existerar redan","nameForm");
    }else{

        const orgContainer = document.querySelector("#orgContainer .content-wrapper");

        if(orgContainer){
            orgContainer.classList.add("loading");
        }

        //Add name change to database
        const domain = store.getState().admin.viewDomain.key;
        const organisation = store.getState().domain.viewOrganization;

        dbRef.child('Domains').child(domain).child('organisations').child(organisation).update({
            "name": store.getState().organization.orgName
        }).then(async function(){

            await getOrganizationsById(store.getState().admin.viewDomain.key);

            if(orgContainer){
                orgContainer.classList.remove("loading");
            }

            showNameInput();
            RenderApp();
        
        });
    }
}



function handleNameChange(newName){
    const name = newName.trim();

    store.dispatch({type: "ORG_NAME", orgName: name});


    const form = document.getElementById('nameForm');

    if(form){
        form.classList.remove("error");
    }
}


function changeBackground(file:any){

    //Get org key
    const org = store.getState().domain.viewOrganization;
    const domain = store.getState().admin.viewDomain.key;

    //Get filetype .png, .jpg etc
    const fileType = file.name.split(".")[1];

    //Create storage ref
    const storageRef = dbStorage.ref(domain + "/" + org + "/background." + fileType);
    
    //Upload file
    const task = storageRef.put(file);

    //Listen on changes while uploading
    task.on('state_changed',
    
        function progress(snapshot:any){
            const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

            const progressBar = document.getElementById("image-progress");

            if(progressBar){
                progressBar.style.width = percentage + "%";
            }
        },
        function error(err){

        },
        function complete(){
            //Get organisation background
            dbStorage.ref(domain + "/" + org + '/background.jpg').getDownloadURL().then(function(url) {
                
                store.dispatch({type: "ORG_BACKGROUND", backgroundUrl: url});
        
                //Register that the org has a background
                dbRef.child('Domains').child(domain).child('organisations').child(org).update({
                    "background": true
                });

                const progressBar = document.getElementById("image-progress");

                if(progressBar){
                    progressBar.style.width = 0 + "%";
                }

                RenderApp();
            });
        }
    );
}

function deleteBackground(){
        //Get org key
        const org = store.getState().domain.viewOrganization;
        const domain = store.getState().admin.viewDomain.key;

        //Create storage ref
        const storageRef = dbStorage.ref(domain + "/" + org + "/background.jpg");

        //delete bg
        storageRef.delete().then(function() {
            store.dispatch({type: "ORG_BACKGROUND", backgroundUrl: ""});

            //Register that there is no background
            dbRef.child('Domains').child(domain).child('organisations').child(org).update({
                "background": false
            }).then(function(){
                RenderApp();
            });
            
            
          }).catch(function(error) {
            // Uh-oh, an error occurred!
          });
}



function confirmDelete(){
    const confirmCont = document.getElementById("delete-org-confirm");
    
    if(confirmCont){
        confirmCont.classList.toggle("visible");
    }
}


function deleteOrg(){

    const org = store.getState().domain.viewOrganization;

    const container = document.querySelector(".loading-container");

    if(container){
        container.classList.add("loading")
    }


    fetch(cloudFunctionsUrl+'/organization', {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            domain: store.getState().admin.viewDomain.key,
            org: org,
            idToken: store.getState().login.idToken,
        })
    
    }).then(async function(response){
        store.dispatch({type: "VIEW_ORGANIZATION", viewOrganization: ""});
        //Update the domain list

        await getUsers(store.getState().admin.viewDomain.domain).then(async function(){
            await getOrganizationsById(store.getState().admin.viewDomain.key).then(function(){
                if(container){
                    container.classList.remove("loading")
                }
        
                transitionState();
            });    
        });

    });

}

function preview(){
    store.dispatch({type:"PREVIEW",preview:true});
    RenderApp(false);
    setTimeout(function(){
        initRipples();
    },600)
    
}


export default function(){
    return <App state={store.getState()} 
        goBack={goBack}
        showNameInput={showNameInput}
        handleNameChange={handleNameChange}
        changeName={changeName}
        showColorInput={showColorInput}
        handleColorChange={handleColorChange}
        changeColor={changeColor}
        confirmDelete={confirmDelete}
        deleteOrg={deleteOrg}
        showContactInput={showContactInput}
        changeContact={changeContact}
        handleContactChange={handleContactChange}
        changeBackground={changeBackground}
        deleteBackground={deleteBackground}
        preview={preview}
        >
        </App>;
}