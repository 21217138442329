import React, {useState,useEffect,useRef} from 'react';
import {dbAuth, dbRef, cloudFunctionsUrl} from '../../../index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export default function(props :any){
    const [email,setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPw, setConfirmPw] = useState("");

    const [emailError,setEmailError] = useState(false);
    const [pwError,setPwError] = useState(false);
    const [confirmPwError,setConfirmPwError] = useState(false);

    const containerRef:any = useRef();
    const formRef:any = useRef();
    const emailErrorRef:any = useRef();
    const pwErrorRef:any = useRef();
    const confirmPwErrorRef:any = useRef();

    useEffect(()=>{
        setEmailError(false);
    },[email]);

    useEffect(()=>{
        setPwError(false);
    },[password]);

    useEffect(()=>{
        setConfirmPwError(false);
    },[confirmPw]);



    const createAccount = () => {

        let error = false;
    
        if(!email){
            error = true;
            emailErrorRef.current.innerHTML = "Fältet får inte vara tomt";
            setEmailError(true);
        }

        if(!password){
            error = true;
            pwErrorRef.current.innerHTML = "Fältet får inte vara tomt";
            setPwError(true);
        }

        if(!confirmPw){
            error = true;
            setConfirmPwError(true);
            confirmPwErrorRef.current.innerHTML = "Fältet får inte vara tomt";
        }else if(confirmPw !== password){
            error = true;
            confirmPwErrorRef.current.innerHTML = "Lösenorden matchar ej";
            setConfirmPwError(true);
        }

        if(error){
            return;
        }

        containerRef.current.classList.add("loading");
    
        dbAuth.createUserWithEmailAndPassword(email, password).then(async function(){

            containerRef.current.classList.remove("loading");
            formRef.current.classList.add("message");

            if(dbAuth.currentUser){

                dbAuth.currentUser.sendEmailVerification().then(()=>{
                    // Email sent.
                }).catch((error:any)=>{
                    // An error happened.
                });

                await fetch(`https://us-central1-tross-ad-platform.cloudfunctions.net/app/user/check_if_disabled?uid=${dbAuth.currentUser.uid}&email=${email}`).then((response:any)=>{
                    return null;
                }).catch(()=>{
                    return null;
                });

                const domain = email.split("@")[1].replace(".",",");
                const sanitizedEmail = email.split(".").join(",");
        
                const creationDate = dbAuth.currentUser.metadata.creationTime;
                const userId = dbAuth.currentUser.uid;

                dbRef.child('Users').child(domain).child(sanitizedEmail).set({
                    creationDate: creationDate,
                    email: email,
                    userId: userId,
                    disabled: false,
                    emailVerified:true,
                });

            }

        }).catch(function(error:any) {
            
            containerRef.current.classList.remove("loading");
    
            const errorCode = error.code;

            if(errorCode === 'auth/invalid-email'){
                setEmailError(true);
                emailErrorRef.current.innerHTML = "Ogiltig mailaddress";
            }else if(errorCode === 'auth/email-already-in-use'){
                setEmailError(true);
                emailErrorRef.current.innerHTML = "Mailaddressen används redan";
            } else if(errorCode === 'auth/weak-password'){
                setPwError(true);
                pwErrorRef.current.innerHTML = "Lösenordet måste vara minst 6 tecken";
            }else if(error.code === "auth/network-request-failed"){
                setConfirmPwError(true);
                confirmPwErrorRef.current.innerHTML = "Kunde inte koppla nå nätverket.";
            }else{
                setConfirmPwError(true);
                confirmPwErrorRef.current.innerHTML = "Något gick fel";
            }

        });

    }



    return(
        <main>
            <div className="main-container">
                <div className="navigation">
                    <button className="button button-ripple no-bg" onClick={props.goBack}>
                        Tillbaka
                        <span className="pulse"></span>
                    </button>
                </div>
                <div className="card-container">
                    <div className="content-wrapper" ref={containerRef}>
                    <div className="content">
                        <h1>Skapa konto</h1>
                        <form className="form" ref={formRef} onSubmit={(e) => {e.preventDefault(); createAccount()}}>
                            <div className={"fieldContainer" + (emailError ? " error" : "")} id="emailField">
                                <span className='input-wrapper'>
                                    <input className={email ? "hasInput" : ""} value={email} name='email' placeholder='&nbsp;' onChange={(e) => {setEmail(e.currentTarget.value)}} type='text' autoComplete='off'/>
                                    <label>Email</label>
                                </span>
                                <div className="errorMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span ref={emailErrorRef}>Ogiltig mail</span></p></div>
                            </div>
                            <div className={"fieldContainer" + (pwError ? " error" : "")} id="emailField" >
                                <span className='input-wrapper'>
                                    <input className={password ? "hasInput" : ""} value={password} name='email' placeholder='&nbsp;' onChange={(e) => {setPassword(e.currentTarget.value)}} type='password' autoComplete='off'/>
                                    <label>Lösenord</label>
                                </span>
                                <div className="errorMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span ref={pwErrorRef}>Ogiltig mail</span></p></div>
                            </div>
                            <div className={"fieldContainer" + (confirmPwError ? " error" : "")} id="emailField">
                                <span className='input-wrapper'>
                                    <input className={confirmPw ? "hasInput" : ""} value={confirmPw} name='email' placeholder='&nbsp;' onChange={(e) => {setConfirmPw(e.currentTarget.value)}} type='password' autoComplete='off'/>
                                    <label>Bekräfta lösenord</label>
                                </span>
                                <div className="errorMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span ref={confirmPwErrorRef}>Ogiltig mail</span></p></div>
                                <div className="loginMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Ett verifikationsmail har skickats!</span></p></div>
                            </div>
                            <button className="button button-ripple" type="submit">
                            Skapa konto
                            <span className="pulse"></span>
                            </button>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
      </main>
    );
  }
  