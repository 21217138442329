export function settingError(message:string, id:string){
    const form:any= document.getElementById(id);
    
    const errorMessage = form.querySelector(".errorMessage p span");
    
    errorMessage.innerHTML = message;
    
    if(form){//Remove error on email change
        form.classList.add("error");
    } 
}