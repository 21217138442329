import React from 'react';
import App from './adRequestComponent';
import {store} from '../../../index';
import './style.scss';




export default function(){
    return <App state={store.getState()}></App>;
}