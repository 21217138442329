import React from 'react'
import {store, RenderApp} from '../../../index';
import {dbAuth} from '../../../index';
import {transitionState} from '../../../common/transitionState';
import App from './forgotPassword';
import {loginError,loginMessage} from '../index';
import {validateEmail} from '../../../common/emailFunctions';


function passwordReset(email:string){
    const container:any = document.querySelector(".card-container .content-wrapper");

    if(!email){
        loginError("Fältet måste vara ifyllt","emailField"); 
        return;
    }else if(!validateEmail(email)){
        loginError("Ogiltig mailaddress","emailField"); 
        return
    }else{

        if(container){
            container.classList.add("loading");
        }

        dbAuth.sendPasswordResetEmail(email).then(function() {
            // Email sent.
            if(container){
                container.classList.remove("loading");
            }
            loginMessage("Återställningsmail har skickats");
        }).catch(function(error) {
            // An error happened.
            if(container){
                container.classList.remove("loading");
            }

            loginError("Något gick fel","emailField"); 
        });
    }

}


function goBack(){
    store.dispatch({type: "FORGOT_PASSWORD", forgotPassword: false});
    store.dispatch({type: "EMAIL", email: ""});
    transitionState();
}


export default function(){
    return <App state={store.getState()} passwordReset={passwordReset} goBack={goBack}></App>;
}


