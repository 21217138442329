
const defaultState = {
    userInfo: {}
};

export default function(state = defaultState, action){

    switch(action.type){
        case 'USER_INFO': {
            return { ...state, userInfo: action.userInfo };
        }
        default:
            return state;
    }
};

