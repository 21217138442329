import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';


export default function (props :any){

    if(props.state.password.passwordChanged){
        return(
          <main className="main-container">
            <div className="navigation">
              <button className="button button-ripple no-bg" onClick={props.goBack}>
                  Tillbaka
                  <span className="pulse"></span>
              </button>
            </div>
            <PasswordChanged state={props.state} newPassword={props.newPassword}></PasswordChanged>
          </main>

        );
    }
    return(
      <main className="main-container">
          <div className="navigation">
            <button className="button button-ripple no-bg" onClick={props.goBack}>
                Tillbaka
                <span className="pulse"></span>
            </button>
          </div>
          <div id="adminPwForm" className="card-container">
              <div className="content-wrapper">
                <div className="content">
                    <h2>Ändra lösenord</h2>
                    <form className="form" onSubmit={props.changePassword}>

                      <div id="adminPassword" className="fieldContainer">
                          <span className='input-wrapper'>
                          <input name='password' value={props.state.password.oldPw} className={props.state.password.oldPw ? "hasInput" : ""} placeholder='&nbsp;' type='password' onChange={(e) => props.oldPwChange(e.currentTarget.value)}  autoComplete='off'></input>
                          <label>Lösenord</label>
                          </span>
                          <div className="errorMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Ogilitigt lösenord</span></p></div>
                      </div>
                      <div id="newPassword" className="fieldContainer">
                          <span className='input-wrapper'>
                          <input name='password' value={props.state.password.newPw} className={props.state.password.newPw ? "hasInput" : ""} placeholder='&nbsp;' type='password' onChange={(e) => props.newPwChange(e.currentTarget.value)}  autoComplete='off'></input>
                          <label>Nytt lösenord</label>
                          </span>
                          <div className="errorMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Ogilitigt lösenord</span></p></div>
                      </div>
                      <div id="confirmPassword" className="fieldContainer">
                          <span className='input-wrapper'>
                          <input name='password' value={props.state.password.confirmPw} className={props.state.password.confirmPw ? "hasInput" : ""} placeholder='&nbsp;' type='password' onChange={(e) => props.confirmPwChange(e.currentTarget.value)}  autoComplete='off'></input>
                          <label>Bekräfta nytt lösenord</label>
                          </span>
                          <div className="errorMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Lösenorden matchar ej</span></p></div>
                      </div>
                        
                      <span className="button-wrapper">
                        <button className="button shadow">
                          Byt lösenord
                        </button>
                        <button className="button button-ripple" style={!props.state.login.isAdmin ? {backgroundColor: props.state.organization.color} : {}} type="submit">
                          Byt lösenord
                          <span className="pulse"></span>
                        </button>
                      </span>
                    </form>
                </div>
            </div>
          </div>
        </main>
    );

  }


  function PasswordChanged(props :any){
    return (
      <div className="card-container">
          <div className="content-wrapper">
            <div className="content">
              <h1>Lösenordet ändrat!</h1>
              <span className="button-wrapper">
                <button className="button shadow">
                  Byt lösenord
                </button>
                <button className="button button-ripple" style={!props.state.login.isAdmin ? {backgroundColor: props.state.organization.color} : {}} onClick={props.newPassword}>
                  Byt lösenord
                  <span className="pulse"></span>
                </button>
              </span>
            </div>
        </div>
      </div>
    );
  }