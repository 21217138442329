import React from 'react';
import App from './headerAdmin';
import {store, dbAuth} from '../../index';
import {transitionState} from '../../common/transitionState';

function goToSettings(){
    
    const settings = !store.getState().password.settings;

    store.dispatch({type: "SETTINGS", settings: settings});

    transitionState();
}


function logout(){

    dbAuth.signOut().then(function() {
        // Sign-out successful.
        store.dispatch({type: "LOGGED_IN", isLoggedIn: false});
        store.dispatch({type: "EMAIL", email: ""});
        store.dispatch({type: "ADMIN", isAdmin: false});
        store.dispatch({type: "PASSWORD", password: ""});

        store.dispatch({type: "NEW_ADMIN_EMAIL", newAdminEmail: ""});
        store.dispatch({type: "SETTINGS", settings: false});

        store.dispatch({type: "OLD_PW", oldPw: ""});
        store.dispatch({type: "NEW_PW", newPw: ""});
        store.dispatch({type: "CONFIRM_PW", confirmPw: ""});
        store.dispatch({type:"PASSWORD_CHANGED", passwordChanged: false});

        store.dispatch({type: "UPDATE_ORGANIZATIONS", organizations: []});
        store.dispatch({type: "VIEW_ORGANIZATION", viewOrganization: ""});
        store.dispatch({type: "ORG_NAME", orgName: ""});

        store.dispatch({type: "VIEW_DOMAIN", viewDomain: ""});
        store.dispatch({type: "ADD_DOMAIN", newDomain: ""});

        store.dispatch({type: "UPDATE_USERS", users: []});

        
        store.dispatch({type:"POSTS_INDEX", lastKnownPostValue: null});
        store.dispatch({type:"POSTS", previousPosts: []});

        store.dispatch({type: "VIEW_USER", viewUser: ""});
        store.dispatch({type: "DOMAIN_KEY", domainKey: ""});

        store.dispatch({type: "ORG_BACKGROUND", backgroundUrl: ""});
        store.dispatch({type: "ORG_COLOR", color: ""});
        store.dispatch({type:"ID_TOKEN", idToken: ""});
        store.dispatch({type:"PREVIEW",preview:false});
        
        localStorage.removeItem('email');
        localStorage.removeItem('password');

        const header = document.querySelector("header");
        if(header){
            header.classList.add("hideHeader");
        }
        
        transitionState();
    }).catch(function(error) {
        // An error happened.
        console.log(error);
    });

}

export default function(){
    return <App state={store.getState()} settings={goToSettings} logout={logout}></App>;
}