import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faExclamationCircle, faBan } from '@fortawesome/free-solid-svg-icons';
import shortid from "shortid";


export default function (props :any){


    const returnVal:any = [];

    const domains = props.state.admin.domains;

    for(let i = 0; i < domains.length; i++){
        returnVal.push(
            <li className="list-item" key={shortid.generate()}>
                <button className="button button-ripple no-bg" onClick={(e) => {props.viewDomain(domains[i].key,domains[i].name)}}>
                    <FontAwesomeIcon icon={faPen}/>
                    <span className="innerText">@{domains[i].name}</span>
                    <span className="pulse"></span>
                </button>
                <div className="info-wrapper">
                    <Info disabled={domains[i].disabled}></Info>
                    <Info warnings={domains[i].warnings}></Info>
                </div>
            </li>           
        );
    }


    return(
            <ul className="list-container">
                <div className="titleWrapper">
                    <h3>Domäner</h3>
                </div>
                {returnVal}
            </ul>  
    );
    
}

function Info(props:any){

    if(props.warnings){
        if(props.warnings.length > 0){

            const returnVal:any = [];
    
            for(let i = 0; i < props.warnings.length; i++){
                returnVal.push(
                    <li key={shortid.generate()}>{props.warnings[i]}</li>
                );
            }
    
        
            return(
        
                <div className="info warning">
                    <FontAwesomeIcon icon={faExclamationCircle}/>
                    <ul>
                        {returnVal}
                    </ul>
                </div>
        
            );
    
        }
    

    }else if(props.disabled){
        return(
        
            <div className="info warning">
                <FontAwesomeIcon icon={faBan}/>
                <ul>
                    <li key={shortid.generate()}>Domän inaktiverad</li>
                </ul>
            </div>
    
        );
    }


    return(
    
        <div></div>

    );


}
