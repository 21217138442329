import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';


export default function (props :any){
    return(
        <div id="addDomain" className="card-container">
            <div className="content-wrapper">
                <div className="content">
                    <form id="domainForm" className="form" onSubmit={props.addDomain}>
                        <div className="fieldContainer">
                            <span className='input-wrapper'>
                            <input name='domain' placeholder='&nbsp;' type='text' onChange={props.domainChange}/>
                            <label>Domän (ex. exempel.se)</label>
                            </span>
                            <div className="errorMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Ogiltig domän</span></p></div>
                            <div className="infoMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Meddelande</span></p></div>
                        </div>
                        <button className="button button-ripple" type="submit">
                            Lägg till domän
                            <span className="pulse"></span>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
