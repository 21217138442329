import React from 'react'
import {store} from '../../../index';
import {transitionState} from '../../../common/transitionState';
import App from './createAccount';


function goBack(){
    store.dispatch({type: "CREATE_ACCOUNT", createAccount: false});
    store.dispatch({type: "EMAIL", email: ""});
    transitionState();
}


export default function(){
    return <App state={store.getState()} goBack={goBack}></App>;
}


