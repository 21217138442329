
import React from 'react';
import {RenderApp, dbRef, store,cloudFunctionsUrl} from '../../../index';
import {formMessage, getOrgInfo} from '../index';
import App from './addPackageComponent';

import {settingError} from '../../../common/errorMessage';

function addPackage(){

    const newPackage = store.getState().organization.newPackage.trim();

    if(!newPackage){
        settingError('Fältet måste vara ifyllt','packageForm');
        return;
    }

    const packages = store.getState().organization.packages;

    for(let i = 0; i < packages.length; i++){
        if(packages[i].toLowerCase() === newPackage.toLowerCase()){
            settingError('Paketet existerar redan','packageForm');
            return;
        }
    }

    const org = store.getState().domain.viewOrganization;

    const domain = store.getState().admin.viewDomain.key;

    dbRef.child('Domains').child(domain).child('organisations').child(org).child('packages').push({
        "name": newPackage
    }).then(async function(){
        
        const input:any = document.querySelector("#packageForm input");

        if(input){//Clear input
            input.value = "";
        }

        store.dispatch({type: "ORG_PACKAGE", newPackage: ""});

        await getOrgInfo(org);

        RenderApp();
    });


    formMessage('Paketet lades till!','packageForm');

}

function packageChange(value:string){

    store.dispatch({type: "ORG_PACKAGE", newPackage: value});

    const form = document.getElementById('packageForm');

    if(form){
        form.classList.remove("error");
        form.classList.remove("message")
    }

}

function deletePackage(name:string){

    const org = store.getState().domain.viewOrganization;
    const domain = store.getState().admin.viewDomain.key;

    const packageContainer = document.getElementById(name);

    if(packageContainer){
        packageContainer.classList.add("loading");
    }


    fetch(cloudFunctionsUrl+'/package', {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            org: org,
            domain: domain,
            name: name,
            idToken: store.getState().login.idToken,
        })
    
    }).then(async function(){ 

        await getOrgInfo(org);

        if(packageContainer){
            packageContainer.classList.remove("loading");
        }

        RenderApp();
        
    });

}

function updateOrder(order){
    store.dispatch({type: "ORG_PACKAGES", packages: order});//Update state

    const org = store.getState().domain.viewOrganization;//Get org key
    const domain = store.getState().admin.viewDomain.key;//Get domain key

    //Get the packages for a specific organization
    const getPackages = dbRef.child('Domains').child(domain).child('organisations').child(org).child('packages');

    let index = 0;

    getPackages.once('value',function(snapshot){
        //Loop through each child and update the name of each to fit the new order
        snapshot.forEach(function(child) {
           
            child.ref.update({
              name: order[index++]
            });

          });

    });

    RenderApp();
}


export default function(){
    return <App state={store.getState()} 
        store={store}
        addPackage={addPackage}
        packageChange={packageChange}
        deletePackage={deletePackage}
        updateOrder={updateOrder}
        >
        </App>;
}