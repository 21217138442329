
const defaultState = {
    type: "",
    email: "",
    password:"",
    isLoggedIn: false,
    isAdmin:false,
    verified: null,
    organization:"",
    idToken:"",
    createAccount:"",
    forgotPassword:"",
};

export default function(state = defaultState, action){

    switch(action.type){
        case 'EMAIL': {
            return { ...state, email: action.email };
        }
        case 'PASSWORD':{
            return { ...state, password: action.password };            
        }
        case 'ADMIN': {
            return { ...state, isAdmin: action.isAdmin };
        }
        case 'LOGGED_IN': {
            return  { ...state, isLoggedIn: action.isLoggedIn };
        }
        case 'VERIFIED': {
            return { ...state, verified: action.verified}
        }
        case 'CREATE_ACCOUNT': {
            return { ...state, createAccount: action.createAccount}
        }
        case 'FORGOT_PASSWORD': {
            return { ...state, forgotPassword: action.forgotPassword}
        }
        case 'SET_ORG': {
            return { ...state, organization: action.organization}
        }
        case 'ID_TOKEN': {
            return { ...state, idToken: action.idToken}
        }
        default:
            return state;
    }
};

