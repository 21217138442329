const defaultState = {
    domains: [],
    newDomain: "",
    viewDomain: "",
    receiverMail: "",
    newReceiverMail: "",
    newAdminEmail: "",
};

export default function(state = defaultState, action){

    switch(action.type){
        case 'UPDATE_DOMAINS': {
            return { ...state, domains: action.domains };
        }
        case 'ADD_DOMAIN': {
            return { ...state, newDomain: action.newDomain };
        }
        case 'VIEW_DOMAIN': {
            return { ...state, viewDomain: action.viewDomain };
        }
        case 'RECEIVER_MAIL': {
            return { ...state, receiverMail: action.receiverMail };
        }
        case 'NEW_MAIL': {
            return { ...state, newReceiverMail: action.newReceiverMail };
        }
        case 'NEW_ADMIN_EMAIL': {
            return { ...state, newAdminEmail: action.newAdminEmail };
        }

        default:
            return state;
    }
};

