
export function getDomainFromEmail(email : string): string{
    return email.split("@")[1].replace(".",",");
}

export function getSenderFromEmail(email : string): string{
    return email.split(".").join(",");
}

export function validateEmail(email : string): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}