import React from 'react';
import App from './headerComponent';
import { dbAuth, store, RenderApp} from '../../index';
import {transitionState} from '../../common/transitionState';


function logout(){

    dbAuth.signOut().then(function() {
        // Sign-out successful.
        store.dispatch({type: "LOGGED_IN", isLoggedIn: false});
        store.dispatch({type: "MESSAGE_SENT", sent: false});
        store.dispatch({type: "EMAIL", email: ""});
        store.dispatch({type: "DESCRIPTION", description: ""});
        store.dispatch({type: "SIZE", size: ""});
        store.dispatch({type: "SET_ORG", organization: ""});
        store.dispatch({type: "ORG_COLOR", color: ""});

        store.dispatch({type:"POSTS_INDEX", lastKnownPostValue: null});
        store.dispatch({type:"POSTS", previousPosts: []});
        store.dispatch({type:"ID_TOKEN", idToken: ""});


        setTimeout(function(){
            store.dispatch({type: "ORG_BACKGROUND", backgroundUrl: ""});
            RenderApp();
        },500);
   
        localStorage.removeItem('email');
        localStorage.removeItem('password');

        const header = document.querySelector("header");
        if(header){
            header.classList.add("hideHeader");
        }

        const background = document.querySelector(".background");
        if(background){
            background.classList.add("hide");
        }


        //Remove postContainer if it exists
        const postContainer = document.querySelector(".transition-container");
       
        if(postContainer){
            postContainer.classList.add("hide");
        }
        


        transitionState();
    }).catch(function(error) {
        // An error happened.
        console.log(error);
    });

}

function goToSettings(){
    
    const settings = !store.getState().password.settings;

    store.dispatch({type: "SETTINGS", settings: settings});
    
    //Remove postContainer if it exists
    const postContainer = document.querySelector(".transition-container");
    
    if(postContainer){
        postContainer.classList.add("hide");
    }
        
    transitionState();
}



/*
function changeOrganisation(){

    store.dispatch({type: "SET_ORG", organization: ""});

    const header = document.querySelector("header");
    if(header){
        header.classList.add("hideHeader");
    }

    const background = document.querySelector(".background");
    if(background){
        background.classList.add("hide");
    }

    setTimeout(function(){
        store.dispatch({type: "ORG_BACKGROUND", backgroundUrl: ""});
        //RenderApp();
    },500);

    //Remove postContainer if it exists
    const postContainer = document.querySelector(".transition-container");

    if(postContainer){
        postContainer.classList.add("hide");
    }

    transitionState();

}
*/

export default function(){
    return <App state={store.getState()} logout={logout} settings={goToSettings}></App>;
}