import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPen, faExclamationTriangle, faInfoCircle, faSearch } from '@fortawesome/free-solid-svg-icons';

import OrgPackages from './addPackageComponent';
import UsersComponent from '../usersComponent';
import JobComponent from '../jobComponent';

export default function (props :any){

    if(props.state.organization.preview){
        return(
            <JobComponent></JobComponent>
        );
    }

    return(
        <main className="main-container">
            <div className="navigation">
                <button className="button button-ripple no-bg" onClick={props.goBack}>
                    Tillbaka
                    <span className="pulse"></span>
                </button>
            </div>
            
            <OrganizationSettings
                state={props.state}
                showNameInput={props.showNameInput}
                changeName={props.changeName}
                handleNameChange={props.handleNameChange}
                showColorInput={props.showColorInput}
                handleColorChange={props.handleColorChange}
                changeColor={props.changeColor}
                showContactInput={props.showContactInput}
                changeContact={props.changeContact}
                handleContactChange={props.handleContactChange}>
            </OrganizationSettings>

            <UploadBackground 
                state={props.state}
                changeBackground={props.changeBackground}
                deleteBackground={props.deleteBackground}
            ></UploadBackground>

            <OrgPackages></OrgPackages>
            
            <UsersComponent></UsersComponent>

            <div className="container-no-bg">
                <button className="button button-ripple" onClick={props.preview}>
                    <FontAwesomeIcon icon={faSearch}/>
                    <span className="innerText">Förhandsgranska</span>
                    <span className="pulse"></span>
                </button>
            </div>

            <DeleteOrg 
                state={props.state}
                confirmDelete={props.confirmDelete}
                deleteOrg={props.deleteOrg}>
            </DeleteOrg>
        </main>
    );
    

}

function OrganizationSettings(props:any){
    return (

        <div className="card-container no-padding" id="orgContainer">
            <div className="content-wrapper">
                <div className="content">
                    <OrganizationName
                        state={props.state}
                        showNameInput={props.showNameInput}
                        changeName={props.changeName}
                        handleNameChange={props.handleNameChange}>
                    </OrganizationName>
                    <OrganizationColor
                        state={props.state}
                        showColorInput={props.showColorInput}
                        handleColorChange={props.handleColorChange}
                        changeColor={props.changeColor}>
                    </OrganizationColor>
                    <OrganizationContact
                        state={props.state}
                        showContactInput={props.showContactInput}
                        changeContact={props.changeContact}
                        handleContactChange={props.handleContactChange}
                        >
                    </OrganizationContact>
                </div>
            </div>
        </div>

    );
}


function UploadBackground(props:any){

    return (
        <div className="card-container no-padding" id="packageContainer">
            <div className="content-wrapper">
                <div className="content">
                    <figure style={props.state.organization.backgroundUrl && props.state.organization.backgroundUrl !== "" ? {display:'block'} : {display:'none'}}>
                        <img src={props.state.organization.backgroundUrl}></img>
                        <button className="button button-ripple no-bg" onClick={props.deleteBackground}>
                            <FontAwesomeIcon icon={faTrash}/>
                            <span className="innerText">Ta bort</span>
                            <span className="pulse"></span>
                        </button>
                    </figure>
                    <div className="setting-container changingInput" style={(props.state.organization.backgroundUrl && props.state.organization.backgroundUrl !== "") ? {display:'none'} : {display:'block'}}>
                        <div className="progress" id="image-progress"></div>
                        <div className="form">
                            <div className="fieldContainer">
                                <label>Lägg till bakgrundsbild</label>
                                <input 
                                    onChange={(e) => {
                                        if(e.currentTarget.files){
                                            props.changeBackground(e.currentTarget.files[0]);
                                        }
                                    }} 
                                    type='file'
                                    accept=".jpg"
                                />
                                
                                <div className="errorMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Fältet får inte vara tomt</span></p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


function OrganizationName(props:any){
    return (
        <div className="content-header">
            <div className="setting-container" id="orgName">
                <h1>{props.state.organization.orgName}</h1>
                <form id="nameForm" className="form" onSubmit={(e) => {e.preventDefault(); props.changeName()}}>
                    <div id="nameContainer" className="fieldContainer">
                        <span className='input-wrapper'>
                        <input 
                            onChange={(e) => props.handleNameChange(e.currentTarget.value)} 
                            name='domain' 
                            placeholder='&nbsp;' 
                            type='text'
                        />
                        <label>Ändra namn</label>
                        </span>
                        <div className="errorMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Fältet får inte vara tomt.</span></p></div>
                    </div>
                </form>
                <div className="actions">
                    <button className="button button-ripple no-bg" 
                        data-org={props.state.admin.viewOrganization}
                        onClick={props.showNameInput}>
                        <FontAwesomeIcon icon={faPen}/>
                        <span className="innerText">Ändra</span>
                        <span className="pulse"></span>
                    </button>
                </div>
            </div>
        </div>
    );
}

function OrganizationColor(props:any){
    return (
        <div className="setting-container" id="colorContainer">
            <div className="wrapper">
                <div className="color-circle" style={{backgroundColor: props.state.organization.orgInfo.color}}></div>
                <span className="setting-title" data-color={props.state.organization.orgInfo.color}>{props.state.organization.orgInfo.color}</span>
            </div>
            <form id="colorForm" className="form" onSubmit={(e) =>{e.preventDefault(); props.changeColor()}}>
                    <div className="fieldContainer">
                        <span className='input-wrapper'>
                        <input 
                            onChange={(e) => props.handleColorChange(e.currentTarget.value)} 
                            name='color' 
                            placeholder='&nbsp;' 
                            type='text'
                        />
                        <label>Ändra färg (ex. #FF7000)</label>
                        </span>
                        <div className="errorMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Ogiltig färg.</span></p></div>
                    </div>
            </form>
            <div className="actions">
                <button className="button button-ripple no-bg" 
                    data-org={props.state.admin.viewOrganization} 
                    onClick={props.showColorInput}>
                    <FontAwesomeIcon icon={faPen}/>
                    <span className="innerText">Ändra</span>
                    <span className="pulse"></span>
                </button>
            </div>
        </div>
    );
}

function OrganizationContact(props:any){
    return (
        <div className={props.state.organization.contact === "" ? "setting-container changingInput" : "setting-container"} id="contactContainer">
            <div className="wrapper">
                <span className="setting-title" data-color={props.state.organization.orgInfo.color}>{props.state.organization.orgInfo.contact}</span>
            </div>
            <form id="contactForm" className="form"  onSubmit={(e) =>{e.preventDefault(); props.changeContact()}}>
                    <div className="fieldContainer">
                        <span className='input-wrapper'>
                        <input 
                            onChange={(e) => props.handleContactChange(e.currentTarget.value)} 
                            name='contact' 
                            placeholder='&nbsp;' 
                            type='text'
                        />
                        <label>Lägg till kontaktmail</label>
                        </span>
                        <div className="errorMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Fältet får inte vara tomt</span></p></div>
                    </div>
            </form>
            <div className="actions">
                <button className="button button-ripple no-bg" 
                    onClick={props.state.organization.contact === "" ? (e) => {} : props.showContactInput}>
                    <FontAwesomeIcon icon={faPen}/>
                    <span className="innerText">Ändra</span>
                    <span className="pulse"></span>
                </button>
            </div>
        </div>
    );
}




function DeleteOrg(props:any){
    return(
        <div className="delete-container">
            <div className="container-no-bg">
                <h4>På egen risk</h4>
                <p>Raderar organisationen {props.state.organization.orgName}.</p>
                <button className="button button-ripple warning" onClick={props.confirmDelete}>
                    <FontAwesomeIcon icon={faExclamationTriangle}/>
                    <span className="innerText">Radera organisation</span>
                    <span className="pulse"></span>
                </button>
            </div>
            <div className="confirm-delete" id="delete-org-confirm">
                <div className="container">
                    <div className="content-wrapper">
                        <div className="content">
                            <h2>Radera {props.state.organization.orgName}?</h2>
                            <div className="confirmButtons">
                                <button className="button button-ripple warning" onClick={props.deleteOrg}>
                                    <FontAwesomeIcon icon={faTrash}/>
                                    <span className="innerText">Ja</span>
                                    <span className="pulse"></span>
                                </button>
                                <button className="button button-ripple no-bg" onClick={props.confirmDelete}>
                                    <span className="innerText">Nej</span>
                                    <span className="pulse"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}