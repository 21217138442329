import React from 'react';
import App from './datePicker';
import {store, RenderApp} from '../../../index';

function handleChange(date){
    store.dispatch({type: "SET_DATE", date: date});

    validDate(date);

    RenderApp();
}

export function validDate(date):boolean{
    const today = new Date();
    today.setHours(0,0,0,0);

    const container = document.getElementById("datePicker");

    if(date < today){//Date has already happended.
        if(container){
            container.classList.add("error");
        }
        return false;
    }else{
        if(container){
            container.classList.remove("error");
        }
        return true;
    }
}


export default function(){
    return <App state={store.getState()} handleChange={handleChange}></App>;
}