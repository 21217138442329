import React, { useEffect, useState, useRef } from 'react';
import { dbRef, store, cloudFunctionsUrl} from '../../../index';
import {formatDate} from '../../userComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faMapMarkerAlt, faInfoCircle, faTimes} from '@fortawesome/free-solid-svg-icons';
import {getSenderFromEmail, getDomainFromEmail} from '../../../common/emailFunctions';


function AdRequestComponent(props:any){

    const [adverts,setAdverts]:any = useState([]);
    //const [awaitingAds,setAwaitingAds]:any = useState([]);

    function getStatusMessage(status:string):string{
        let statusMessage = "";
    
        if(status === "sent"){
            statusMessage = "Skickad, inväntar bekräftelse.";
        }else if(status === "confirmed"){
            statusMessage = "Bekräftad";
        }else if(status === "active"){
            statusMessage = "Aktiv";
        }else if(status === "done"){
            statusMessage = "Klar";
        }
    
        return statusMessage;
    }

    useEffect(()=>{

        /*Retrieve a list of all adverts posted*/
        dbRef.child('Users').once('value',function(snapshot){
            const advertList:any = [];
            const awaitingAdsList:any = [];

            const keys = Object.keys(snapshot.val())
            for (const key of keys) {
                const userKeys = Object.keys(snapshot.val()[key]);
                for (const userKey of userKeys) {

                    if ('JobAdverts' in snapshot.val()[key][userKey]){
                        const advertKeys = Object.keys(snapshot.val()[key][userKey].JobAdverts);
                        for (const advertKey of advertKeys) {
                            const advert = snapshot.val()[key][userKey].JobAdverts[advertKey];
                            advert.email = snapshot.val()[key][userKey].email;
                            advert.userId = snapshot.val()[key][userKey].userId;
                            advert.organisation = snapshot.val()[key][userKey].organisation;
                            advert.statusMessage = getStatusMessage(advert.status);
                            advert.key = advertKey;
                            advert.userKey = userKey;
                            advert.domainKey = key;

                            advertList.push(advert);
                            if(advert.status === "confirmed"){
                                awaitingAdsList.push(advert);
                            }

                        }
                    }

                }
            }

            /*Sortlist by date & if they are */
            awaitingAdsList.sort((a,b)=>{

                if(new Date(a.date) > new Date(b.date))
                    return 1;
                else if(new Date(a.date) < new Date(b.date))
                    return -1;
                else 
                    return 0;

            });

            setAdverts([...awaitingAdsList]);

            //setAdverts([...advertList]); 

        });


    },[]);

    useEffect(()=>{
        console.log(adverts)
    },[adverts]);


    return(
        <>
            {adverts.length > 0 && 
            <div className="posts-container">
                <div>
                    <h2>Annonsförfrågningar</h2>
                    <ul>
                        {adverts.map((advert:any,index:number)=>{
                            return(
                                <ListItem key={index} advert={advert} setAdverts={setAdverts}/>
                            );
                        })

                        }
                    </ul>
                </div>
            </div>}
        </>

    );
}

function ListItem(props:any){
    const listRef:any = useRef();
    const [modalOpen,setModalOpen] = useState(false);

    function changeStatus(status:string){
    
        const sender = getSenderFromEmail(props.advert.email);
        const domain = getDomainFromEmail(props.advert.email);
    
        const post = dbRef.child('Users').child(domain).child(sender).child('JobAdverts').child(props.advert.key);
    
        const container = document.querySelector(".expand");
    
        if(container){
            container.classList.add("loading");
        }
    
        post.update({
            "status":status
        }).then(function(){
    
    
        }).then(function(){
            
            fetch(cloudFunctionsUrl+'/jobRequest/sendStatusUpdate', {
                method: 'POST',
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    status: status,
                    email: props.advert.email,
                    postKey: props.advert.key,
                })
            })
    
        }).catch(function(){
            if(container){
                container.classList.remove("loading");
            }
        });
    
    }

    const toggleExpand = (event) => {

        //Retrieve previous opened list item
        const previousListItem:any = document.querySelector(".posts-container .post-item.expand");

        if(previousListItem){//Close the previous item
            previousListItem.classList.remove("expand");
            previousListItem.querySelector(".description").style.height = 0 + "px";

            if(previousListItem.querySelector(".listHeader") !== event.target){//Only open the newly clicked list item unless wasn't the previously opened
                const description = listRef.current.querySelector(".description");
                const content:any = description.querySelector(".description-content");
                const height = content.offsetHeight;
            
                description.style.height = height + "px";
            
                listRef.current.classList.add("expand");
            }

        }else{//If there isn't a previously opened value, open without closing
            const description = listRef.current.querySelector(".description");
            const content:any = description.querySelector(".description-content");
            const height = content.offsetHeight;
        
            description.style.height = height + "px";
        
            listRef.current.classList.add("expand");
        }

    }


    return(
        <>
        <li className="post-item" ref={listRef}>
            <div className="header-wrapper">
                <div className={props.advert.status + " status"}>{props.advert.statusMessage}</div>
                <button className="listHeader button-ripple" onClick={(e)=>{e.preventDefault(); toggleExpand(e);}}>
                    <div className="column"><b>{formatDate(new Date(props.advert.date))}</b></div>
                    <div className="column">{props.advert.email}</div>
                    <div className="column">{props.advert.title}</div>
                    <span className="pulse"></span>
                </button>
            </div>

            <div className="description">
                <div className="description-content">
                    <div className="detail-container">
                        <FontAwesomeIcon icon={faCalendarAlt}/>
                        <div>
                            <p><span className="title">Datum skickat</span><br></br>{formatDate(new Date(props.advert.date))}</p>
                            <p><span className="title">Startdatum</span><br></br>{formatDate(new Date(props.advert.startDate))}</p>
                        </div>
                    </div>
                    <div className="detail-container">
                        <FontAwesomeIcon icon={faMapMarkerAlt}/>
                        <div>
                            <p><span className="title">Ungefärlig position</span> {props.advert.city} {props.advert.country}</p>
                            <p><span className="title">IP</span> {props.advert.ip}</p>
                        </div>
                    </div>

                    <h4>Annonsnamn</h4>
                    <p>{props.advert.title}</p>
                    <h4>Faktura referens</h4>
                    <p>{props.advert.reference}</p>
                    <h4>Skickat av</h4>
                    <p>{props.advert.email}</p>
                    <h4>Övrig info</h4>
                    <p>{props.advert.description}</p>
                    <h4>Nuvarande status</h4>
                    <p>{props.advert.statusMessage}</p>

                    <div>
                        <div className="change-status">
                            <button className="button button-ripple active" onClick={(e) => {setModalOpen(true)}}>
                                Aktivera
                                <span className="pulse"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        {modalOpen && <ConfirmModal advert={props.advert} setModalOpen={setModalOpen} setAdverts={props.setAdverts}></ConfirmModal>}
        </>
    );
}


function ConfirmModal(props:any){
    const [id,setId] = useState("");

    const modalRef:any = useRef();
    const formRef:any = useRef();    
    const errorRef:any = useRef();
    const messageRef:any = useRef();


    useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", closeModal);
        // return function to be called when unmounted
        return () => {
          document.removeEventListener("mousedown", closeModal);
        };
      }, []);

    const closeModal = (e) => {
       
        if (modalRef.current.contains(e.target)) {
            // inside click
            return;
          }
          // outside click 
          props.setModalOpen(false);

    }

    useEffect(()=>{
        if(id){
            formRef.current.classList.remove("error");
        }
    },[id]);

    const sendIdForm = async () => {
        const container = modalRef.current.querySelector(".content");
        container.classList.add("loading");

        if(!id){//Check if empty field
            container.classList.remove("loading");
            errorRef.current.innerText = "Fältet får inte vara tomt.";
            formRef.current.classList.add("error");
            return;
        }

        const input = Number(id);

        if(isNaN(input)){//Check if number
            container.classList.remove("loading");
            errorRef.current.innerText = "Fältet ska bara innehålla siffror";
            formRef.current.classList.add("error");
            return;
        }


        const exist = await fetch(`https://us-central1-tross-ad-platform.cloudfunctions.net/app/campaign/exist?id=${id}`).then((response:any) => {
            return response.json();
        }).then((json:any)=>{
            console.log(json)
            return json.exists;
        });


        if(!exist){
            container.classList.remove("loading");
            errorRef.current.innerText = "Annons id existerar inte.";
            formRef.current.classList.add("error");
            return;
        }

        const adRequest = dbRef.child('Users').child(props.advert.domainKey).child(props.advert.userKey).child('JobAdverts').child(props.advert.key);
        
        adRequest.update({
            adId: input,
            status: "active",
        }).then(()=>{

            fetch(cloudFunctionsUrl+'/jobRequest/sendStatusUpdate', {
                method: 'POST',
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    status: "active",
                    email: props.advert.email,
                    postKey: props.advert.key,
                })
            });
            
            container.classList.remove("loading");
            formRef.current.classList.add("message");

            props.setAdverts((adverts)=>{
                const arr = [...adverts];
                const newArr = arr.filter((advert)=>{
                    return advert.key !== props.advert.key;
                });
                return newArr;
            });
    

            return;
        });


    }

    return(
        <div className="id-modal">
            <div className="card-container" ref={modalRef}>
                <div className="content-wrapper">
                    <div className="content">
                        <button className="button button-ripple no-bg close-button" onClick={(e)=>{e.preventDefault(); props.setModalOpen(false);}}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </button>
                        <form className="form" ref={formRef} onSubmit={(e)=>{e.preventDefault(); sendIdForm();}}>
                            <div className="fieldContainer" id="titleContainer">
                                <span className='input-wrapper'>
                                <input className={id ? "hasInput" : ""} value={id} onChange={(e) => {setId(e.currentTarget.value)}} placeholder='&nbsp;'></input>
                                <label>Id nummer för annons</label>
                                </span>
                                <div className="errorMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span ref={errorRef}>Fältet får inte vara tomt.</span></p></div>
                                <div className="loginMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span ref={messageRef}>Annonsförfrågan aktiverad!</span></p></div>
                            </div>
                            <button className="button button-ripple" type="submit">
                                Aktivera annons
                                <span className="pulse"></span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default AdRequestComponent;
