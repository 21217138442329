import React,{useState, useEffect} from 'react';
import shortid from "shortid";
import { ReactSortable as Sortable } from 'react-sortablejs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faInfoCircle } from '@fortawesome/free-solid-svg-icons';


export default function (props :any){

    return(
        <div className="card-container no-padding" id="packageContainer">
            <div className="content-wrapper">
                <div className="content">
                    <form id="packageForm" className="form" onSubmit={(e) => {e.preventDefault(); props.addPackage();}}>
                        <div className="fieldContainer">
                            <span className='input-wrapper'>
                            <input name='domain' placeholder='&nbsp;' type='text' onChange={(e) => {props.packageChange(e.target.value)}}/>
                            <label>Paket (ex. Grafisk profil)</label>
                            </span>
                            <div className="errorMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Ogiltigt paket</span></p></div>
                            <div className="infoMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Meddelande</span></p></div>
                        </div>
                        <button className="button button-ripple" type="submit">
                            Lägg till paket
                            <span className="pulse"></span>
                        </button>
                    </form>
                </div>
            </div>
            <div className="content-wrapper">
                <div className="content">
                    <PackageList store={props.store} state={props.state} deletePackage={props.deletePackage} updateOrder={props.updateOrder}></PackageList>
                    {/*<MovableList store={props.store} state={props.state} deletePackage={props.deletePackage} updateOrder={props.updateOrder}></MovableList>*/}
                </div>
            </div>
        </div>
    );
}

function PackageList(props:any){

    const [values,setValue] = useState([...props.state.organization.packages])

    useEffect(()=>{
        setValue([...props.state.organization.packages])
    },[props.state.organization.packages])

    return(
        <>
            {values.map(val => {
                return(
                    <div className="setting-container" key={shortid.generate()} data-id={val} id={val}>
                        <div className="wrapper">
                            <span className="setting-title">{val}</span>
                        </div>
                        <div className="actions">
                            <button data-name={val} className="button button-ripple no-bg" onClick={(e) => {props.deletePackage(val)}}>
                                <FontAwesomeIcon icon={faTrash}/>
                                <span className="pulse"></span>
                            </button>
                        </div>
                    </div>
                );
                })
            }
        </>
    );
}


function MovableList(props:any){
    const values = props.state.organization.packages;
    const items = values.map(val => (
        <div className="setting-container" key={shortid.generate()} data-id={val} id={val}>
            <div className="wrapper">
                <span className="setting-title">{val}</span>
            </div>
            <div className="actions">
                <button data-name={val} className="button button-ripple no-bg" onClick={(e) => {props.deletePackage(val)}}>
                    <FontAwesomeIcon icon={faTrash}/>
                    <span className="pulse"></span>
                </button>
            </div>
        </div>
        )
    );

    return(
        <>
            <Sortable
                tag="div" // Defaults to "div"
                onChange={(order, sortable, evt) => {
                    props.updateOrder(order);
                }}
            >
                {items}
            </Sortable>
        </>
    );

    /*render() {
        const { items } = this.state;
        var listItems = items.map((item, i) => {
          return (
            <SortableItem
              key={i}
              onSortItems={this.onSortItems}
              items={items}
              sortId={i}>{item}</SortableItem>
          );
        });
     
        return (
          <ul className='sortable-list'>
            {listItems}
          </ul>
        )
      }
      */



}