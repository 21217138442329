
const defaultState = {
    oldPw: "",
    newPw: "",
    confirmPw: "",
    settings: false,
    passwordChanged: false,
};

export default function(state = defaultState, action){

    switch(action.type){
        case 'OLD_PW': {
            return { ...state, oldPw: action.oldPw };
        }
        case 'NEW_PW':{
            return { ...state, newPw: action.newPw };
        }
        case 'CONFIRM_PW':{
            return { ...state, confirmPw: action.confirmPw }
        }
        case 'SETTINGS':{
            return { ...state, settings: action.settings}
        }
        case 'PASSWORD_CHANGED':{
            return { ...state, passwordChanged: action.passwordChanged}
        }
        default:
            return state;
    }
};

