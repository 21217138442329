import React from 'react';
import {dbRef, store} from '../../../index';
import {transitionState} from '../../../common/transitionState';
import App from './domainListComponent';

import {getUsers} from '../../usersComponent';
import {getOrganizationsById} from '../../domainComponent';

async function viewDomain(domainKey:string,domain:string){

    const container = document.querySelector(".loading-container");

    if(container){
        container.classList.add("loading")
    }

    let disabled;

    await dbRef.child('Domains').child(domainKey).once('value',function(snapshot){
        disabled = snapshot.val().disabled;
    });

    const viewDomain = {
        key: domainKey,
        domain: domain,
        disabled:disabled,
    }

    store.dispatch({type: "VIEW_DOMAIN", viewDomain: viewDomain});
    store.dispatch({type: "ADD_DOMAIN", newDomain: ""});
    
    await getOrganizationsById(domainKey).then(async function(){
        await getUsers(domain);

        if(container){
            container.classList.remove("loading")
        }
        transitionState();
    });

}




export default function(){
    return <App 
        state={store.getState()} 
        viewDomain={viewDomain}
        >
        </App>;
}
