import React from 'react';
import {RenderApp, dbRef, store} from '../../../index';
import App from './addOrgComponent';
import {formMessage} from '../../orgComponent';
import {getOrganizationsById} from '../index';

function addOrganization(event : React.FormEvent<HTMLInputElement>){
    event.preventDefault();

    const orgName = store.getState().domain.newOrg.trim();

    if(!orgName){//Empty field
        orgError("Fältet får inte vara tomt");
    }else if(orgExists(orgName)){
        orgError("Organisation existerar redan");
    }else{//Valid domain

        const container = document.querySelector("#addOrg .content-wrapper");

        if(container){//Add a loading animation
            container.classList.add("loading");
        }

        dbRef.child('Domains').child(store.getState().admin.viewDomain.key).child("organisations").push({//Add domain to databse
            name: orgName,
            color:"#FF7000",
            contact:null,
        }).then(async function(){ 
            //retrieve the updated list
            await getOrganizationsById(store.getState().admin.viewDomain.key);

            const input:any = document.querySelector("#orgForm input")

            if(input){//Clear input field§
                input.value = "";
            }

            store.dispatch({type: "NEW_ORGANIZATION", newOrg: ""});

            if(container){//Add a loading animation
                container.classList.remove("loading");
            }
        
            formMessage("Organisationen lades till", "orgForm")

            RenderApp();

        });

    }
}

function handleOrgChange(newName){
    const name = newName;

    store.dispatch({type: "NEW_ORGANIZATION", newOrg: name});


    const form:any= document.getElementById("orgForm");
    
    if(form){//Remove error on email change
        form.classList.remove("error");
        form.classList.remove("message");
    } 
}



function orgError(message:string){
    const form:any= document.getElementById("orgForm");
    
    const errorMessage = form.querySelector(".errorMessage p span");
    
    errorMessage.innerHTML = message;
    
    if(form){//Remove error on email change
        form.classList.add("error");
    } 
}

export function orgExists(org: string): boolean{
    const orgs = store.getState().domain.organizations;

    for(let i = 0; i < orgs.length; i++){
        if(orgs[i].name.toLowerCase() === org.toLowerCase()){
            return true;
        }
    }

    return false;
}


export default function(){
    return <App 
        state={store.getState()} 
        handleOrgChange={handleOrgChange}
        addOrganization={addOrganization}
        >
        </App>;
}
