import {RenderApp} from '../../index';

export async function transitionState(){
    const container:any = document.querySelector(".card-container");
    let prevHeight = container.offsetHeight;

    if(container){
        container.classList.add("slide-out");
        container.classList.add("overflow-hidden");
    }

    setTimeout(function(){
        if(container){
            container.classList.remove("slide-out");
        }
        RenderApp();

        /*Set the height to toggle animation*/
        const main:any = document.querySelector(".card-container");
        
        if(main){
            main.classList.add("overflow-hidden");
            const child = main.querySelector(".content");
            if(child){
                //First set the previous height
                //main.style.height = prevHeight + "px";
                //Then set the new height to be able to animate between them
                //main.style.height = child.offsetHeight + "px";  

                const distance = Math.abs(child.offsetHeight - prevHeight);
                const time = 15;
                const growSpeed = distance / time;  //Pixels/frame
                setHeight(child.offsetHeight,prevHeight,prevHeight,growSpeed,main);

                /*setTimeout(function(){
                    main.style.height = "auto"; //Set to auto when height has transitioned
                    main.classList.remove("overflow-hidden");
                },300);*/
            }
        }


    },300);
    
}

function setHeight(newHeight,oldHeight,currentHeight,growSpeed, elt){

    if(currentHeight === newHeight){
        elt.style.height = "auto";
        elt.classList.remove("overflow-hidden");
        return;
    }

    if(newHeight < oldHeight){

        currentHeight -= growSpeed;

        if(currentHeight <= newHeight){
            elt.classList.remove("overflow-hidden");
            elt.style.height = "auto";
            return;
        }

        elt.style.height = currentHeight + "px";

    }else if(newHeight > oldHeight){

        currentHeight += growSpeed

        if(currentHeight >= newHeight){
            elt.classList.remove("overflow-hidden");
            elt.style.height = "auto";
            return;
        }

        
        elt.style.height = currentHeight + "px";

    }

    window.requestAnimationFrame(function(){
        setHeight(newHeight,oldHeight,currentHeight,growSpeed,elt);
    });

}
