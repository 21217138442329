
const defaultState = {
    adminPassword: ""
};

export default function(state = defaultState, action){

    switch(action.type){

        case 'PASSWORD':{
            return { ...state, adminPassword: action.adminPassword };
        }
        default:
            return state;
    }
};

