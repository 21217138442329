const defaultState = {
    organizations: [],
    viewOrganization: "",
    newOrg: "",
    users: [],
    viewUser: "",
};

export default function(state = defaultState, action){

    switch(action.type){
        case 'UPDATE_ORGANIZATIONS': {
            return { ...state, organizations: action.organizations };
        }
        case 'VIEW_ORGANIZATION': {
            return { ...state, viewOrganization: action.viewOrganization };
        }
        case 'NEW_ORGANIZATION': {
            return { ...state, newOrg: action.newOrg };
        }
        case 'UPDATE_USERS': {
            return { ...state, users: action.users };
        }
        case 'VIEW_USER': {
            return { ...state, viewUser: action.viewUser };
        }
        default:
            return state;
    }
};

