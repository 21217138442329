import React from 'react';
import {dbRef, store, RenderApp, cloudFunctionsUrl} from '../../index';
import App from './domainComponent';
import { getOrgInfo } from '../orgComponent';
import {transitionState} from '../../common/transitionState';
import {getDomains} from '../adminComponent/addDomainComponent';
import {getUsers} from '../usersComponent';
import {getAdmins} from '../adminComponent';

/*Retrieve a list of all organizations (including some info)*/
export async function getOrganizationsById(key:string = ""){
    
    const organisations : any[] = [];

    const getOrgs = dbRef.child('Domains').child(key).child("organisations");

    await getOrgs.once("value").then(function(snapshot){


        snapshot.forEach(function(childSnapshot){
            const name = childSnapshot.val().name;
            const key = childSnapshot.key;
            const contact = childSnapshot.val().contact;
            const packages: any = childSnapshot.val().packages;
            const background = childSnapshot.val().background;

            const orgObj = {
                name:name,
                key:key,
                contact:contact,
                packages:packages,
                background:background,
            }

            organisations.push(orgObj);
        });

    });

    store.dispatch({type: "UPDATE_ORGANIZATIONS", organizations: organisations});
}

/*Retrieve a list of all organizations*/
export async function getOrganizationsByDomain(domain:string = ""){
    
    const organisations : any[] = [];

    const getDomainKey = dbRef.child('Domains').orderByChild('domain').equalTo(domain);

    //GET the domain key
    let key;

    await getDomainKey.once('value',function(snapshot){

        snapshot.forEach(function(childSnapshot){

            key = childSnapshot.key;

        });

    }).then(async function(){

        const getOrgs = dbRef.child('Domains').child(key).child("organisations");

        await getOrgs.once("value").then(function(snapshot){
    
    
            snapshot.forEach(function(childSnapshot){

                const name = childSnapshot.val().name;
                const key = childSnapshot.key;
                const contact = childSnapshot.val().contact;
                const packages: any = childSnapshot.val().packages;
                const background = childSnapshot.val().background;
    
                const orgObj = {
                    name:name,
                    key:key,
                    contact:contact,
                    packages:packages,
                    background:background,
                }

                organisations.push(orgObj);
            });
    
        });

        store.dispatch({type: "UPDATE_ORGANIZATIONS", organizations: organisations});

    });

}


async function viewOrgDetails(event : React.FormEvent<HTMLInputElement>){
    event.preventDefault();
    
    const org = ""+event.currentTarget.dataset.org;
    const orgName = ""+event.currentTarget.dataset.name;

    const container = document.querySelector(".loading-container");

    if(container){
        container.classList.add("loading")
    }

    store.dispatch({type: "VIEW_ORGANIZATION", viewOrganization: org});
    store.dispatch({type: "ORG_NAME", orgName: orgName});
    store.dispatch({type: "NEW_ORGANIZATION", newOrg: ""});
    
    

    await getOrgInfo(org).then(async function(){

        await getUsers(store.getState().admin.viewDomain.domain).then(function(){
            if(container){
                container.classList.remove("loading")
            }
            transitionState();
        });

    });

}

async function goBack(){
    
    store.dispatch({type: "VIEW_DOMAIN", viewDomain: ""});

    const container = document.querySelector(".loading-container");

    if(container){
        container.classList.add("loading")
    }

    await getDomains();

    await getAdmins();


    if(container){
        container.classList.remove("loading")
    }

    transitionState();
}



function disableDomain(){

    const domain = store.getState().admin.viewDomain.key;

    const container = document.querySelector(".loading-container");

    if(container){
        container.classList.add("loading")
    }


    fetch(cloudFunctionsUrl+'/domain', {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            domain: domain,
            idToken: store.getState().login.idToken,
        })
    
    }).then(function(response){
        return response.json();
    }).then(async function(data){

        const viewDomain = store.getState().admin.viewDomain;
        viewDomain.disabled = data.disabled;

        store.dispatch({type: "VIEW_DOMAIN", viewDomain: viewDomain});


        if(container){//remove loading animation
            container.classList.remove("loading");
        }

        RenderApp();

    }).catch(function(error){
        if(container){//remove loading animation
            container.classList.remove("loading");
        }
    });

}



function confirmDelete(){
    const confirmCont = document.getElementById("delete-confirm");
    
    if(confirmCont){
        confirmCont.classList.toggle("visible");
    }
}

function deleteDomain(){

    const domain = store.getState().admin.viewDomain.key;

    const container = document.querySelector(".loading-container");

    if(container){
        container.classList.add("loading")
    }

    fetch(cloudFunctionsUrl+'/domain', {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            domain: domain,
            idToken: store.getState().login.idToken,
        })
    
    }).then(async function(response){
        await getDomains(); //Update the domain list
        
        store.dispatch({type: "VIEW_DOMAIN", viewDomain: ""});

        if(container){
            container.classList.remove("loading")
        }

        transitionState();

    });
}


export default function(){
    return <App 
        state={store.getState()} 
        viewDetails={viewOrgDetails}
        disableDomain={disableDomain}
        deleteDomain={deleteDomain}
        confirmDelete={confirmDelete}
        goBack={goBack}
        >
        </App>;
}
