import React from 'react';
import {RenderApp, dbRef, store, dbAuth} from '../../index';
import App from './pwChangeComponent';
import {transitionState} from '../../common/transitionState';

function oldPwChange(password){
    const oldPw = password;
    store.dispatch({type:"OLD_PW", oldPw: oldPw});

    const field:any = document.getElementById("adminPassword");

    if(field){
        field.classList.remove("error");
    } 

    RenderApp(false);

}

function newPwChange(password){
    const newPw = password;
    store.dispatch({type:"NEW_PW", newPw: newPw});

    const field:any = document.getElementById("newPassword");

    if(field){
        field.classList.remove("error");
    } 
    RenderApp(false);
}

function confirmPwChange(password){
    const confirmPw = password;
    store.dispatch({type:"CONFIRM_PW", confirmPw: confirmPw});

    const field:any = document.getElementById("confirmPassword");

    if(field){
        field.classList.remove("error");
    } 
    RenderApp(false);
}

function changePassword(event : React.FormEvent<HTMLInputElement>){
    event.preventDefault();

    const email = store.getState().login.email;
    const oldPw = store.getState().password.oldPw.trim();
    const newPw = store.getState().password.newPw.trim();
    const confirmPw = store.getState().password.confirmPw.trim();


    if(validInput(oldPw, newPw, confirmPw)){   //Check input for errors

        const container = document.querySelector("#adminPwForm .content-wrapper");

        if(container){
            container.classList.add("loading");
        }

        //Sign in to check if old password matches
        dbAuth.signInWithEmailAndPassword(email, oldPw).then(function(){
            let user = dbAuth.currentUser;

            if(user){

                //Update with new password
                user.updatePassword(newPw).then(() => {
                    if(container){
                        container.classList.remove("loading");
                    }
        
                    store.dispatch({type:"PASSWORD_CHANGED", passwordChanged: true});
                    transitionState();
        
                }, (error) => {
                    // An error happened.
                    if(container){
                        container.classList.remove("loading");
                    }

                    inputError("Ogiltigt lösenord","adminPassword");
                });

            }else{
                if(container){
                    container.classList.remove("loading");
                }
                inputError("Något gick fel","adminPassword");
            }

        }).catch(function(error) {
            // An error happened.
            if(container){
                container.classList.remove("loading");
            }
            inputError("Gamla lösenordet matchar ej","adminPassword");
          });
          
    }
}

function validInput(oldPw, newPw, confirmPw){
    
    let valid = true;

    if(!oldPw){//Check if empty field
        inputError("Fältet måste vara ifyllt","adminPassword");
        valid = false;
    }

    if(!newPw){//Check if empty field
        inputError("Fältet måste vara ifyllt","newPassword");
        valid = false;
    }else if(newPw.length < 6){
        inputError("Lösenordet måste vara minst 6 tecken.","newPassword");
        valid = false;
    }

    if(!confirmPw){//Check if empty field
        inputError("Fältet måste vara ifyllt","confirmPassword");
        valid = false;
    }

    if(newPw && confirmPw && newPw !== confirmPw){//Throw an error if not the same
        inputError("Lösenorden matchar ej","confirmPassword");
        valid = false;
    }

    return valid;
}

function inputError(message: string, id: string){
    const field:any = document.getElementById(id);

    const errorMessage = field.querySelector(".errorMessage p span");

    errorMessage.innerHTML = message;

    if(field){
        field.classList.add("error");
    } 
}

function newPassword(){
    store.dispatch({type:"PASSWORD_CHANGED", passwordChanged: false});
    transitionState();
}


function goBack(){
    
    const settings = !store.getState().password.settings;

    store.dispatch({type: "SETTINGS", settings: settings});

    transitionState();
}


export default function(){
    return <App state={store.getState()} 
        oldPwChange={oldPwChange} 
        newPwChange={newPwChange} 
        confirmPwChange={confirmPwChange}
        changePassword={changePassword}
        newPassword={newPassword}
        goBack={goBack}>
    </App>;
}