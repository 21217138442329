
const defaultState = {
    previousPosts: [],
    lastKnownPostValue: null
};

export default function(state = defaultState, action){

    switch(action.type){
        case 'POSTS': {
            return { ...state, previousPosts: action.previousPosts };
        }
        case 'POSTS_INDEX': {
            return { ...state, lastKnownPostValue: action.lastKnownPostValue };
        }
        default:
            return state;
    }
};

