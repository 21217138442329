import React from 'react';
import DatePicker from "react-datepicker";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import "react-datepicker/dist/react-datepicker.css";
import "./datePicker.css";


export default function(props :any){
  
    const date = new Date(props.state.job.date);

    return( 
        <div className="fieldContainer date" id="datePicker">
            <label>Välj startdatum</label>
            <DatePicker
                selected={date}
                startDate={date}
                minDate={new Date()}
                onChange={props.handleChange}
                placeholderText='&nbsp;'
            />
            <div className="errorMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Ogiltigt startdatum</span></p></div>

        </div>
    );
}