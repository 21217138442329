import React from 'react';
import {RenderApp, dbRef, store, cloudFunctionsUrl} from '../../index';
import App from './usersComponent';
import {transitionState} from '../../common/transitionState';
import {getPreviousPosts} from '../postsComponent';
import {getUserInfo} from '../userComponent';


function goBack(){
    store.dispatch({type: "VIEW_DOMAIN", viewDomain: ""});
    store.dispatch({type: "DOMAIN_KEY", domainKey: ""});
    
    transitionState();
}

/*Get valid domains for a specific domain*/
export async function getUsers(val){

    const domain = val.replace(".",",");

    //If the a specific organisation is viewed then it should get only users in that org

    const org = store.getState().domain.viewOrganization;

    let itemArray;

    let users;

    if(org){

        users = dbRef.child('Users').child(domain).orderByChild('organisation').equalTo(org);

    }else{
        users = dbRef.child('Users').child(domain);
    }


    itemArray = await users.once("value").then(function(snapshot:any){
            
        const array : any = [];

        snapshot.forEach(function(childSnapshot:any) {

            const key = childSnapshot.key;
            const userId = childSnapshot.val().userId;
            const disabled = childSnapshot.val().disabled;
            const email = childSnapshot.val().email;
            const confirmed = childSnapshot.val().confirmed;
            let isAdmin = false;

            const obj = {
                key: key, 
                email: email,
                userId: userId,
                disabled: disabled,
                domain: domain,
                isAdmin: isAdmin,
                confirmed: confirmed,
            };

            array.push(obj);

        });
        return array;
    });

    /*Loop through array to see if any is admin*/
    for(let i = 0; i < itemArray.length; i++){

        await dbRef.child('Admins').orderByChild("email")
        .equalTo(itemArray[i].email).limitToFirst(1).once("value",function(admin:any){

            if(admin.exists()){
                itemArray[i].isAdmin = true;
            }
        });

    }

    store.dispatch({type: "UPDATE_USERS", users: itemArray});
}




async function goToUser(user){

    store.dispatch({type: "VIEW_USER", viewUser: user});

    const container = document.querySelector(".loading-container");

    if(container){
        container.classList.add("loading")
    }

    await getUserInfo(user);

    store.dispatch({type:"POSTS_INDEX", lastKnownPostValue: null});
    store.dispatch({type: "POSTS", previousPosts: []});

    await getPreviousPosts(user.split(",").join("."));

    if(container){
        container.classList.remove("loading")
    }

    transitionState();

}

function enableAccount(user, element){

    const container = element.parentNode.parentNode;

    container.classList.add('loading');


    fetch(cloudFunctionsUrl+'/disableUser', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            userId: user.userId,
            domain: user.domain,
            key: user.key,
            disabled: false,
        })
    
    }).then(async function(response){
        await getUsers(user.domain);
        container.classList.remove('loading');
        RenderApp();
    });
}


function disableAccount(user, element){

    const container = element.parentNode.parentNode;
    container.classList.add('loading');

    fetch(cloudFunctionsUrl+'/disableUser', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            userId: user.userId,
            domain: user.domain,
            key: user.key,
            disabled: true,
        })
    
    }).then(async function(response){
        await getUsers(user.domain);
        container.classList.remove('loading');
        RenderApp();
    });
}


export default function(){
    return( 
        <App 
            state={store.getState()} 
            goBack={goBack} 
            goToUser={goToUser} 
            disableAccount={disableAccount} 
            enableAccount={enableAccount}

        ></App>);
}