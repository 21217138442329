import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faMapMarkerAlt, faInfoCircle} from '@fortawesome/free-solid-svg-icons';

export default function(props :any){
    const returnVal:any = [];

    const posts = props.state.posts.previousPosts;

    if(props.state.login.isAdmin){

        for(let i = 0; i < posts.length; i++){
    
            returnVal.push(
                <li key={posts[i].key} className="post-item" >
                    <div className="header-wrapper">
                        <div className={posts[i].status + " status"}>{posts[i].statusMessage}</div>
                        <button className="listHeader button-ripple" onClick={props.expand}>
                            <div className="column"><b>{posts[i].date}</b></div>
                            <div className="column">{posts[i].size}</div>
                            <div className="column">{posts[i].title}</div>
                            <span className="pulse"></span>
                        </button>
                    </div>
                    <div className="description">
                        <div className="description-content">
                            <div className="detail-container">
                                <FontAwesomeIcon icon={faCalendarAlt}/>
                                <div>
                                    <p><span className="title">Datum skickat</span><br></br>{posts[i].fullDate}</p>
                                    <p><span className="title">Startdatum</span><br></br>{posts[i].startDate}</p>
                                </div>
                            </div>
                            <div className="detail-container">
                                <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                <div>
                                    <p><span className="title">Ungefärlig position</span> {posts[i].city} {posts[i].country}</p>
                                    <p><span className="title">IP</span> {posts[i].ip}</p>
                                </div>
                            </div>
                            <h4>Annonsnamn</h4>
                            <p>{posts[i].title}</p>
                            <h4>Fakturareferens</h4>
                            <p>{posts[i].reference}</p>
                            <h4>Övrig info</h4>
                            <p>{posts[i].description}</p>
                            <h4>Nuvarande status</h4>
                            <p>{posts[i].statusMessage}</p>
                            {posts[i].adId && 
                                <>
                                    <h4>Länk till statistik</h4>
                                    <p><a href={"https://tross-ad-platform.web.app/dashboard/campaign/" + posts[i].adId} target="_blank">{posts[i].adId}</a></p>
                                </>
                            }
                            <ChangeStatus post={posts[i]} changeStatus={props.changeStatus}></ChangeStatus>
                        </div>
                    </div>
                        
                </li>           
            );

        }
    }else{
        for(let i = 0; i < posts.length; i++){
            returnVal.push(
                <li key={posts[i].key} className="post-item">
                    <div className="header-wrapper">
                        <div className={posts[i].status + " status"}>{posts[i].statusMessage}</div>
                        <button className="listHeader button-ripple" onClick={props.expand}>
                            <div className="column"><b>{posts[i].date}</b></div>
                            <div className="column">{posts[i].size}</div>
                            <div className="column">{posts[i].title}</div>
                            <span className="pulse"></span>
                        </button>
                    </div>
                    <div className="description">
                        <div className="description-content">
                            <div className="detail-container">
                                <FontAwesomeIcon icon={faCalendarAlt}/>
                                <div>
                                    <p><span className="title">Datum skickat</span><br></br>{posts[i].fullDate}</p>
                                    <p><span className="title">Startdatum</span><br></br>{posts[i].startDate}</p>
                                </div>
                            </div>
                            <h4>Annonsnamn</h4>
                            <p>{posts[i].title}</p>
                            <h4>Fakturareferens</h4>
                            <p>{posts[i].reference}</p>
                            <h4>Övrig info</h4>
                            <p>{posts[i].description}</p>
                            <h4>Status</h4>
                            <p>{posts[i].statusMessage}</p>
                            {posts[i].adId && 
                                <>
                                    <h4>Länk till statistik</h4>
                                    <p><a href={"https://tross-ad-platform.web.app/dashboard/campaign/" + posts[i].adId} target="_blank">{posts[i].adId}</a></p>
                                </>}
                        </div>
                    </div>
                    
                </li>           
            );
        }
    }


    if(returnVal.length == 0){
        return (
            <div className="transition-container" key={props.state.domain.viewUser}>
                <ul className="list-container">
                    <li className="list-item">
                        <button className="button button-ripple no-bg disabled">
                            <span className="innerText">Ingen meddelandehistorik</span>
                            <span className="pulse"></span>
                        </button>
                        <div className="info">
                            <FontAwesomeIcon icon={faInfoCircle}/>
                        </div>
                    </li>
                </ul> 
            </div>
        );
    }

    return(
        <div className="transition-container" key={props.state.domain.viewUser}>
            <div className="posts-container">  
                <div>
                    <h2>Historik</h2>
                    <ul>
                        {returnVal}
                    </ul>
                </div>
            </div>
            <div className="container-no-bg">
                <span className="button-wrapper">
                  <button className="button shadow">
                    Ladda historik
                  </button>
                  <button className="button button-ripple" style={props.state.login.isAdmin ? {} : {backgroundColor: props.state.organization.color}} onClick={props.loadHistory}>
                    Ladda historik
                    <span className="pulse"></span>
                  </button>

                </span>
            </div>
      </div>
    );
  }
  

function ChangeStatus(props:any){
   return(
    <React.Fragment>
        <div className="change-status">
            <button className="button button-ripple confirm" onClick={(e) => {props.changeStatus("confirmed",props.post.key)}}>
                Bekräfta
                <span className="pulse"></span>
            </button>
            <button className="button button-ripple active" onClick={(e) => {props.changeStatus("active",props.post.key)}}>
                Aktiv
                <span className="pulse"></span>
            </button>
            <button className="button button-ripple done" onClick={(e) => {props.changeStatus("done",props.post.key)}}>
                Klar
                <span className="pulse"></span>
            </button>
        </div>
    </React.Fragment>
   ); 
}