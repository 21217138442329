import React,{useState} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import CreateAccountComponent from './createAccount';
import ForgotpasswordComponent from './forgotPassword';

export default function(props :any){

    const [email,setEmail] = useState("");
    const [password,setPassword] = useState("");

    const login = () => {
      props.login(email,password);
    }


    if(props.state.login.createAccount){
      return(
        <CreateAccountComponent></CreateAccountComponent>
      );
    }else if(props.state.login.forgotPassword){
      return(
        <ForgotpasswordComponent></ForgotpasswordComponent>
      );
    }else{

      return(
        <main>
        <div className="main-container">
            <div className="navigation">
                <button className="button button-ripple no-bg invisible">
                    Tillbaka
                    <span className="pulse"></span>
                </button>
            </div>
            <div className="card-container">
              <div className="content-wrapper">
                <div className="content login">
                    <h1>Inloggning</h1>
                    <form id="loginForm" className="form" onSubmit={(e) => {e.preventDefault(); login();}}>
                      <div className="fieldContainer" id="emailField">
                        <span className='input-wrapper'>
                          <input className={email ? "hasInput" : ""} name='email' placeholder='&nbsp;' value={email} onChange={(e) => {setEmail(e.currentTarget.value)}} type='text' autoComplete='on'/>
                          <label>Email</label>
                        </span>
                        <div className="errorMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Ogiltig mailaddress</span></p></div>
                      </div>
                      <div className="fieldContainer" id="passwordField">
                        <span className='input-wrapper'>
                          <input className={password ? "hasInput" : ""} name='password' placeholder='&nbsp;' value={password} onChange={(e) => {setPassword(e.currentTarget.value)}} type='password' autoComplete='on'/>
                          <label>Lösenord</label>
                        </span>
                        <div className="errorMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Ogiltigt lösenord</span></p></div>
                        <div className="loginMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Meddelande</span></p></div>
                      </div>
                      <button className="button button-ripple" type="submit">
                        Logga in
                        <span className="pulse"></span>
                      </button>
                    </form>
                    <div className="login-footer">
                      <button className="button button-ripple no-bg" onClick={props.goToForgotPw}>
                          Glömt lösenord?
                          <span className="pulse"></span>
                      </button>
                      <button className="button button-ripple no-bg" onClick={props.goToCreateAccount}>
                          Skapa ett konto
                          <span className="pulse"></span>
                      </button>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </main>
      );

    }
  
  }
  