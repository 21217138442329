import React from 'react';
import {dbRef, store, RenderApp, cloudFunctionsUrl} from '../../index';
import App from './adminComponent';
import { formMessage } from '../orgComponent';
import {validateEmail} from '../../common/emailFunctions';

import {settingError} from '../../common/errorMessage';

export async function getAdminInfo(){

    await getAdmins();


    await dbRef.child('Admin').once("value").then(function(snapshot) {

        if(snapshot.exists()){
            const mail = snapshot.val().receiveMail;

            store.dispatch({type: "RECEIVER_MAIL", receiverMail: mail});
        
            store.dispatch({type: "NEW_MAIL", newReceiverMail: mail});

        }
    });

}


export async function getAdmins(){

    await fetch(cloudFunctionsUrl+'/admin', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }    
    }).then(async function(response){
        return response.json();
    }).then(function(json){

        const admins = json.admins;

        const adminArray : any = [];

        for(let i = 0; i < admins.length; i++){

            const key = admins[i].split(".").join(",");
            const userId = admins[i].split("@")[1].replace(".",",");
            const email = admins[i];
            let isAdmin = true;

            const obj = {
                key: key, 
                email: email,
                userId: userId,
                isAdmin: isAdmin,
            };

            adminArray.push(obj);

        }

        store.dispatch({type: "UPDATE_USERS", users: adminArray});
    });

}



function addAdmin(){

    const email = store.getState().admin.newAdminEmail.toLowerCase().trim();

    let error = false;

    if(!email){
        settingError("Fältet måste vara ifyllt.","adminEmail");
        error = true;
    }else if(!validateEmail(email)){
        settingError("Ogiltig mailaddress.","adminEmail");
        error = true;
    }

    if(error){
        return;
    }

    const form = document.getElementById("adminForm");

    if(form){
        form.classList.add("loading");
    }


    fetch(cloudFunctionsUrl+'/admin', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: email,
            idToken: store.getState().login.idToken,
        })
    }).then(async function(response){ 

        await getAdmins().then(function(){

            if(form){
                form.classList.remove("loading");
            }
    
            RenderApp();
            
            if(response.status === 201){
                formMessage("Administratör lades till!", "adminForm");
            }else if(response.status === 401){
                settingError("Obehörig administratörsmail.","adminEmail");   
            }else if(response.status === 409){
                settingError("Administratören existerar redan.","adminEmail");   
            }else{
                settingError("Något gick fel.","adminEmail");
            }
    
        });


    }).catch(function(error){
        
        if(form){
            form.classList.remove("loading");
        }

        settingError("Något gick fel.","adminEmail");    
    });
       

}

function handleNewAdminEmail(email:string){
    store.dispatch({type: "NEW_ADMIN_EMAIL", newAdminEmail: email});

    const form:any= document.getElementById("adminForm");

    const emailInput:any= document.getElementById("adminEmail");
    
    if(form){//Remove message
        form.classList.remove("message");
    } 

    if(emailInput){//Remove error on email change
        emailInput.classList.remove("error");
    } 
}

function changeReceiver(){

    const mail = store.getState().admin.newReceiverMail;

    if(!validateEmail(mail)){ //Check if valid email
        settingError("Ogiltig mailaddress","receiverForm");
    }else{
        const container = document.getElementById("receiverContainer");

        if(container){
            container.classList.add("loading");
        }
    
    
        //Update database
        dbRef.child('Admin').update({
            "receiveMail": mail
        }).then(async function(){
    
            if(container){
                container.classList.remove("loading");
            }

            store.dispatch({type: "RECEIVER_MAIL", receiverMail: mail});

            formMessage('Mottagaraddress ändrades','receiverForm');
        });
    }

}

function handleReceiverChange(receiver:string){
    store.dispatch({type: "NEW_MAIL", newReceiverMail: receiver});


    const form:any= document.getElementById("receiverForm");
    
    if(form){//Remove error on email change
        form.classList.remove("error");
        form.classList.remove("message");
    } 
}

export default function(){
    return <App 
        state={store.getState()}
        changeReceiver={changeReceiver}
        handleReceiverChange={handleReceiverChange}
        addAdmin={addAdmin}
        handleEmailChange={handleNewAdminEmail}
        >
        </App>;
}
