
import React from 'react';
import {store, dbRef, dbAuth} from '../../index';
import App from './userComponent';
import {transitionState} from '../../common/transitionState';
import {getDomainFromEmail} from '../../common/emailFunctions';

function goBack(){
    store.dispatch({type: "VIEW_USER", viewUser: ""});

    transitionState();
}

export function formatDate(date: Date, hours:boolean = true){
    const months = ["Januari","Februari","Mars","April","Maj","Juni","Juli","Augusti","September","Oktober","November","December"];
    const days = ["Söndag","Måndag","Tisdag","Onsdag","Torsdag","Fredag","Lördag"];


    let formattedDate = days[date.getDay()] + ", " + date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();

    if(hours){
        formattedDate += ", " + ((date.getHours()) < 10 ? "0" + (date.getHours()) : (date.getHours()))+ ":";
        formattedDate += date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    }

    return formattedDate;
}



export async function getUserInfo(user){

    const domain = getDomainFromEmail(user);

    const getUserInfo = dbRef.child('Users').child(domain).child(user);

    let userInfo;

    await getUserInfo.once("value").then(function(snapshot) {

        if(snapshot.exists()){


            const creationDate = formatDate(new Date(snapshot.val().creationDate));
            let lastLogin;

            if(snapshot.val().lastLogin){
                lastLogin = formatDate(new Date(snapshot.val().lastLogin));
            }else{
                lastLogin = "Aldrig"
            }


  



            const emailVerified = snapshot.val().emailVerified;
            const email = snapshot.val().email;
            let isAdmin = false;
            const confirmed = snapshot.val().confirmed;
            const organization = snapshot.val().organisation;

            const obj = {
                creationDate: creationDate.toString(), 
                lastLogin: lastLogin.toString(),
                emailVerified: emailVerified,
                email: email,
                isAdmin: isAdmin,
                confirmed:confirmed,
                organization: organization,
            };

            userInfo = obj;
        }

    }).then(async function(){

        /*Check if admin*/
        await dbRef.child('Admins').orderByChild("email")
        .equalTo(userInfo.email).limitToFirst(1).once("value",function(admin:any){
    
            if(admin.exists()){
                userInfo.isAdmin = true;
            }
        });

    });

    store.dispatch({type: "USER_INFO", userInfo: userInfo});
}



export default function(){
    return <App state={store.getState()} goBack={goBack}></App>;
}
