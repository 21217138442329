import React from 'react';
import shortid from "shortid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faCheckCircle, faSearch } from '@fortawesome/free-solid-svg-icons';

import DatePicker from './datePicker';

export default function(props :any){
    const valueArr:Array<string> = props.state.organization.packages;

    if(props.state.login.isAdmin){
      return(
        <React.Fragment>
          <div className={props.state.organization.backgroundUrl ? "background" : "background hide"} style={{backgroundImage: "url('" + props.state.organization.backgroundUrl + "')", backgroundSize: 'cover', backgroundPosition: 'center'}}></div>
          <main>
          <div className="main-container">
              <div className="navigation">
                  <button className="button button-ripple no-bg invisible">
                      Tillbaka
                      <span className="pulse"></span>
                  </button>
              </div> 
            <div className="card-container">
              <div className="content-wrapper">
                <div className="content">
                  <h1>Lägg till jobbförfrågan</h1>        
                  <form className="form" onSubmit={(e) => {e.preventDefault()}}>
                    <div className="fieldContainer" id="sizeContainer">
                      <span className='input-wrapper select'>
                        <SelectMenu state={props.state} expandSelect={props.handleJob.expandSelect} typeChange={props.handleJob.typeChange} values={valueArr}></SelectMenu>
                        <label className={props.state.job.size ? "selected" : ""}>Välj paket</label>
                      </span>
                    </div>

                    <DatePicker></DatePicker>

                    <div className="fieldContainer" id="descriptionContainer">
                      <span className='input-wrapper'>
                        <textarea placeholder='&nbsp;'></textarea>
                        <label>Övrig info</label>
                      </span>
                    </div>
                    <span className="button-wrapper">
                      <button className="button shadow">
                        Skicka
                      </button>
                      <button className="button button-ripple" style={{backgroundColor: props.state.organization.color}}>
                        Skicka
                        <span className="pulse"></span>
                      </button>

                    </span>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="container-no-bg">
            <span className="button-wrapper">
              <button className="button shadow">
                &nbsp;
              </button>
              <button className="button button-ripple" style={{backgroundColor: props.state.organization.color}} onClick={props.closePreview}>
                <FontAwesomeIcon icon={faSearch}/>
                <span className="innerText">Stäng förhandsgranskning</span>
                <span className="pulse"></span>
              </button>
            </span>
          </div>
        </main> 

      </React.Fragment>
      );


    }

    if(props.state.job.sent){
      return(
        <main>
          <div className="main-container">
            <div className="navigation">
                <button className="button button-ripple no-bg invisible">
                    Tillbaka
                    <span className="pulse"></span>
                </button>
            </div> 
            <MessageSent state={props.state} newMessage={props.handleJob.newMessage}></MessageSent>
          </div>
        </main>
      );
    }else{
      return(
        <main>
          <div className="main-container">
              <div className="navigation">
                  <button className="button button-ripple no-bg invisible">
                      Tillbaka
                      <span className="pulse"></span>
                  </button>
              </div> 
            <div className="card-container">
              <div className="content-wrapper">
                <div className="content">
                  <h1>Lägg till jobbförfrågan</h1>        
                  <form className="form" onSubmit={props.handleJob.send}>

                    <div className="fieldContainer" id="sizeContainer">
                      <span className='input-wrapper select'>
                        <SelectMenu state={props.state} expandSelect={props.handleJob.expandSelect} typeChange={props.handleJob.typeChange} values={valueArr}></SelectMenu>
                        <label className={props.state.job.size ? "selected" : ""}>Välj paket</label>
                      </span>
                      <div className="errorMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Fältet får inte vara tomt.</span></p></div>
                    </div>

                    <DatePicker></DatePicker>

                    <div className="fieldContainer" id="titleContainer">
                      <span className='input-wrapper'>
                        <input className={props.state.job.title ? "hasInput" : ""} value={props.state.job.title} onChange={(e) => props.handleJob.titleChange(e.currentTarget.value)} placeholder='&nbsp;'></input>
                        <label>Annonsnamn</label>
                      </span>
                      <div className="errorMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Fältet får inte vara tomt.</span></p></div>
                    </div>

                    <div className="fieldContainer" id="referenceContainer">
                      <span className='input-wrapper select-container'>
                        <input className={props.state.job.reference ? "hasInput" : ""} value={props.state.job.reference} onChange={(e) => props.handleJob.referenceChange(e.currentTarget.value)} onClick={props.handleJob.expandSelect} placeholder='&nbsp;'></input>
                        <label>Fakturareferens</label>
                        <SelectOptions values={props.state.job.oldReferences} selectValue={props.handleJob.selectReference}></SelectOptions>
                      </span>
                      <div className="errorMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Fältet får inte vara tomt.</span></p></div>
                    </div>

                    <div className="fieldContainer" id="descriptionContainer">
                      <span className='input-wrapper'>
                        <textarea className={props.state.job.description ? "hasInput" : ""} value={props.state.job.description} onChange={(e) => props.handleJob.descriptionChange(e.currentTarget.value)} placeholder='&nbsp;'></textarea>
                        <label>Övrig info</label>
                      </span>
                      <div className="errorMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Fältet får inte vara tomt.</span></p></div>
                    </div>
                    <span className="button-wrapper">
                      <button className="button shadow">
                        Skicka
                      </button>
                      <button className="button button-ripple" style={{backgroundColor: props.state.organization.color}} type='submit'>
                        Skicka
                        <span className="pulse"></span>
                      </button>

                    </span>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      );
    }
}
  
function MessageSent(props :any){
  return (
    <div className="card-container">
      <div className="content-wrapper">
          <div className="content">
            <div className="message-sent">
                <h1><FontAwesomeIcon icon={faCheckCircle}/>Annonsen skickades!</h1>
                <span className="button-wrapper">
                  <button className="button shadow">
                    Skapa ny annons
                  </button>
                  <button className="button button-ripple" style={{backgroundColor: props.state.organization.color}} onClick={props.newMessage}>
                    Skapa ny annons
                    <span className="pulse"></span>
                  </button>
                </span>
              </div>
            </div>
          </div>
      </div>
  );
}

function SelectOptions(props:any){
  let returnVal:any = [];

  for(let i:number = 0; i < props.values.length; i++){
    returnVal.push(
      <button className="select-item button-ripple" tabIndex={0} key={props.values[i].toString()} onClick={(e) => {e.preventDefault(); props.selectValue(props.values[i], e.currentTarget.parentNode);} }>
        {props.values[i]}
        <span className="pulse"></span>
      </button>
    );
  }

  return (
    <div className="item-container">
      <div className="wrapper">
        {returnVal}
      </div>
    </div>
  );

}


function SelectMenu(props :any){
    
  return (
    <div className="select-container">
      <button className="selected-value button-ripple" tabIndex={0} onClick={props.expandSelect}>
        <span className="text">&nbsp;{props.state.job.size}</span>
        <span className="pulse"></span>
      </button>
      <SelectOptions values={props.values} selectValue={props.typeChange}></SelectOptions>
    </div>
  );
}
