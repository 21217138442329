import React from 'react';
import App from './chooseOrgComponent';
import {store, dbAuth, cloudFunctionsUrl} from '../../index';
import { dbRef} from '../../index';
import {getDomainFromEmail, getSenderFromEmail} from '../../common/emailFunctions';

import {loginMessage} from '../loginComponent';

import {transitionState} from '../../common/transitionState';




function expandSelect(event){
    event.preventDefault();
    const parent = event.currentTarget.parentNode;
    if(parent){
        parent.classList.toggle("expanded");
    }

    document.addEventListener('click', function(event) {
        const isClickInside = parent.contains(event.target);
      
        if (!isClickInside) {
          parent.classList.remove("expanded");
        }
    });
  

}


function handleOrgChange(orgObj:any,elt){

    if(elt.parentNode){
        elt.parentNode.classList.toggle("expanded");
        elt.parentNode.childNodes[0].querySelector(".text").innerHTML = orgObj.name;
        elt.parentNode.parentNode.childNodes[1].classList.add("selected");
    }

    const container = document.getElementById("orgContainer");

    if(container){
        container.classList.remove("error");
    }


    store.dispatch({type: "SET_ORG", organization: orgObj.key});
}


async function submitOrg(){

    const container = document.getElementById("orgContainer");

    const org = store.getState().login.organization;

    if(container && org == ""){
        container.classList.add("error");
    }else{

        const email = store.getState().login.email;
    
        const domainKey = getDomainFromEmail(email); //Gets the last 
        const sanitizedEmail = getSenderFromEmail(email);   //Replaces . with , 
    
        //Set the organisation of user
        const updateOrganisation = dbRef.child('Users').child(domainKey).child(sanitizedEmail);
        
        updateOrganisation.update({
            organisation: org,
        });

        await sendConfirmationEmail();

    }

}

function sendConfirmationEmail(){
    
    const container = document.querySelector(".card-container .content-wrapper");

    if(container){//Add a loading animation
        container.classList.add("loading");
    }

    const email = store.getState().login.email;
    const org = store.getState().login.organization;

    /*Sends confirmation email that a new user is added to a organization */
    fetch(cloudFunctionsUrl+'/confirmUser', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email: email,
            org: org,
        })
    }).then(function(){

        if(container){//Add a loading animation
            container.classList.remove("loading");
        }

        dbAuth.signOut().then(async function(){
            store.dispatch({type: "LOGGED_IN", isLoggedIn: false});
            transitionState();
            
            setTimeout(function(){
                const input:any = document.querySelector("#loginForm input[type=text]");

                if(input){
                    input.value = email;
                }

                loginMessage("Förfrågan inväntar validering.");


            },600);

        });




    });


}


export default function(){
    return <App 
        state={store.getState()}
        expandSelect={expandSelect}
        handleOrgChange={handleOrgChange}
        submitOrg={submitOrg}
        ></App>;
}