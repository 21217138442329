import React,{useState} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';


export default function(props :any){
    const [email,setEmail] = useState("");
    
    const resetPassword = () => {
        props.passwordReset(email);
    }

    return(
        <main>
            <div className="main-container">
                <div className="navigation">
                    <button className="button button-ripple no-bg" onClick={props.goBack}>
                        Tillbaka
                        <span className="pulse"></span>
                    </button>
                </div>
                <div className="card-container">
                    <div className="content-wrapper">
                    <div className="content">
                        <h1>Glömt lösenord?</h1>
                        <form id="loginForm" className="form" onSubmit={(e) => {e.preventDefault(); resetPassword();}}>
                            <div className="fieldContainer" id="emailField">
                            <span className='input-wrapper'>
                                <input className={email ? "hasInput" : ""} value={email} name='email' placeholder='&nbsp;' onChange={(e) => {setEmail(e.currentTarget.value)}} type='text' autoComplete='off'/>
                                <label>Email</label>
                            </span>
                            <div className="errorMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Ogiltig mail</span></p></div>
                            <div className="loginMessage message"><p><FontAwesomeIcon icon={faInfoCircle}/><span>Meddelande</span></p></div>
                            </div>
                            <button className="button button-ripple" type="submit">
                                Återställ lösenord
                            <span className="pulse"></span>
                            </button>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
      </main>
    );
  }
  