import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserShield } from '@fortawesome/free-solid-svg-icons';

import PostsComponent from '../postsComponent';
import UsersComponent from '../usersComponent';


export default function (props :any){

    return(
        <main className="main-container">
            <div className="navigation">
                <button className="button button-ripple no-bg" onClick={props.goBack}>
                    Tillbaka
                    <span className="pulse"></span>
                </button>
            </div>
            <div className="card-container" key={props.state.user.userInfo.key}>
                <div className="content-wrapper">
                    <div className="content">
                        <div className="content-header">
                            <div className="title-wrapper">
                                <h1><FontAwesomeIcon icon={props.state.user.userInfo.isAdmin ? faUserShield : faUser}/> {props.state.domain.viewUser.split(",").join(".")}</h1>
                            </div>
                        </div>
                        <div className="userInfo">
                            <Status userInfo={props.state.user.userInfo}></Status>
                            <p><b>Senast inloggad</b> {props.state.user.userInfo.lastLogin}</p>
                            <p><b>Kontot skapat</b> {props.state.user.userInfo.creationDate}</p>
                        </div>
                    </div>     
                </div>               
            </div>
            <PostsComponent></PostsComponent>
            <UsersComponent></UsersComponent>
        </main>
    );
}


function Status(props:any){
    
    if(props.userInfo.emailVerified && props.userInfo.confirmed){
        return(
            <p><b>Status</b> Godkänd användare</p>
        );
    }

    if(props.userInfo.emailVerified && props.userInfo.organization){
        return(
            <p><b>Status</b> Inväntar godkännande</p>
        );
    }

    if(props.userInfo.emailVerified){
        return(
            <p><b>Status</b> Verifierad email</p>
        );
    }else{
        return(
            <p><b>Status</b> Overifierad email</p>
        );
    }


}