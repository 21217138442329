import React from 'react';
import shortid from "shortid";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserShield, faUserSlash, faUserCheck, faCheckCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export default function (props :any){


    return(
        <Users 
            state={props.state} 
            goToUser={props.goToUser} 
            disableAccount={props.disableAccount}
            enableAccount={props.enableAccount}
            title={props.title}
        ></Users>


    );
}



function Users(props:any){
    const returnVal:any = [];

    const users = props.state.domain.users;

    if(users.length === 0){
        returnVal.push(

            <li className="list-item" key={shortid.generate()}>
                <button className="button button-ripple no-bg disabled">
                    <span className="innerText">Inga användare</span>
                    <span className="pulse"></span>
                </button>
                <div className="info">
                    <FontAwesomeIcon icon={faInfoCircle}/>
                </div>
            </li>    
        );
    }else{
        for(let i = 0; i < users.length; i++){

            if(users[i].confirmed){
                returnVal.push(
                    <li className="list-item" key={users[i].key}>
                        <div className="wrapper">
                            <div className="user-confirmed">
                                <FontAwesomeIcon icon={faCheckCircle}/>
                                <ul className="toolTip">
                                    <li>Godkänd användare</li>
                                </ul>
                            </div>
                            <button className="button button-ripple no-bg" data-user={users[i].key} onClick={e => props.goToUser(e.currentTarget.dataset.user)}>
                                <FontAwesomeIcon icon={users[i].isAdmin ? faUserShield : faUser}/>
                                <span className="innerText">{users[i].email}</span>
                                <span className="pulse"></span>
                            </button>
                            <div className="actions">
                                <EnableDisableAccount state={props.state} user={users[i]} disableAccount={props.disableAccount} enableAccount={props.enableAccount}></EnableDisableAccount>
                            </div>
                        </div>
                    </li>           
                );
            }else{
                returnVal.push(
                    <li className="list-item" key={shortid.generate()}>
                        <div className="wrapper">
                            <button className="button button-ripple no-bg" data-user={users[i].key} onClick={e => props.goToUser(e.currentTarget.dataset.user)}>
                                <FontAwesomeIcon icon={users[i].isAdmin ? faUserShield : faUser}/>
                                <span className="innerText">{users[i].email}</span>
                                <span className="pulse"></span>
                            </button>
                            <div className="actions">
                                <EnableDisableAccount state={props.state} user={users[i]} disableAccount={props.disableAccount} enableAccount={props.enableAccount}></EnableDisableAccount>
                            </div>
                        </div>
                    </li>           
                );
            }
    
        }
    }

 


    return(
        <ul className="list-container user-container">
            <div className="titleWrapper">
                <h3>{!props.state.admin.viewDomain ? "Administratörer" : "Användare"}</h3>
            </div>
            {returnVal}
        </ul>  
    );
}

function EnableDisableAccount(props:any){
    if(props.user.isAdmin){
        return(
            <div></div>
        );
    }

    if(props.user.key.split(",").join(".") !== props.state.login.email){

        if(props.user.disabled){
            return(
                <button className="button button-ripple" onClick={e => props.enableAccount(props.user, e.currentTarget)}>
                    <FontAwesomeIcon icon={faUserCheck}/>
                    <span className="innerText">Aktivera</span>
                    <span className="pulse"></span>
                </button>
            );
        }else{

            return(
                <button className="button button-ripple warning" onClick={e => props.disableAccount(props.user, e.currentTarget)}>
                    <FontAwesomeIcon icon={faUserSlash}/>
                    <span className="innerText">Inaktivera</span>
                    <span className="pulse"></span>
                </button>
            );

        }

    }else{
        return(
            <div></div>
        );
    }

}
