import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCog } from '@fortawesome/free-solid-svg-icons';

export default function (props :any){
  /*
  return(
    <header>
        <div className="header-wrapper">
          <button className="button button-ripple no-bg" onClick={props.changeOrganisation}>
                <FontAwesomeIcon icon={faCog}/>
                <span className="innerText">{props.state.organization.orgName}</span>
                <span className="pulse"></span>
          </button>
          <span className="button-wrapper">
            <button className="button shadow">
            Logga ut
            </button>
            <button style={{backgroundColor: props.state.organization.color}} className="button button-ripple" onClick={props.logout}>
              Logga ut
              <span className="pulse"></span>
            </button>
          </span>
        </div>
    </header>
                <button className="button button-ripple no-bg disabled">
                  {props.state.organization.orgName}
                  <span className="pulse"></span>
            </button>
  );
    */

    return(
      <header>
          <div className="header-wrapper">
            <button className={props.state.login.organization ? "button button-ripple no-bg" : "button button-ripple no-bg disabled"} onClick={props.state.login.organization ? props.settings : (e) => {}}>
                {props.state.login.organization ? <FontAwesomeIcon icon={faUserCog}/> : ""}
                <span className="innerText">{props.state.login.email}</span>
                <span className="pulse"></span>
            </button>

            <span className="button-wrapper">
              <button className="button shadow">
              Logga ut
              </button>
              <button style={{backgroundColor: props.state.organization.color}} className="button button-ripple" onClick={props.logout}>
                Logga ut
                <span className="pulse"></span>
              </button>
            </span>
          </div>
      </header>
    );
  }